import { Avatar, Box, Card, CardContent, Typography, Stack } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import art from '../assets/classBackgrounds/art.jpg';
import coding from '../assets/classBackgrounds/coding.jpg';
import computerScience from '../assets/classBackgrounds/computerScience.jpg';
import drama from '../assets/classBackgrounds/drama.jpg';
import english from '../assets/classBackgrounds/english.jpg';
import history from '../assets/classBackgrounds/history.jpg';
import math from '../assets/classBackgrounds/math.jpg';
import music from '../assets/classBackgrounds/music.jpg';
import physicalEducation from '../assets/classBackgrounds/physicalEducation.jpg';
import science from '../assets/classBackgrounds/science.jpg';
import technology from '../assets/classBackgrounds/technology.jpg';

const SubjectCard = ({ className, index, Icon, available }) => {
    const navigate = useNavigate();

    const backgrounds = {
        "Art": art,
        "Coding & Robotics": coding,
        "Computer Science": computerScience,
        "Drama": drama,
        "English": english,
        "History": history,
        "Math": math,
        "Music": music,
        "Physical Education": physicalEducation,
        "Science": science,
        "Technology": technology,
        "Design & Technology": technology,
        "Coding & Robotics": coding,
    }
    const subjectColors = {
        "Art": "#F48FB1",                   // Soft Pink
        "Coding & Robotics": "#AB47BC",     // Purple
        "Computer Science": "#FF7043",      // Burnt Orange
        "Drama": "#FFC107",                 // Warm Gold
        "English": "#00C6D6",               // Aqua Cyan
        "History": "#FF7043",               // Burnt Orange (matches the historical tone)
        "Math": "#D1C4E9",                  // Lavender
        "Music": "#E57373",                 // Light Coral
        "Physical Education": "#4CAF50",    // Vibrant Green
        "Science": "#FFD700",               // Bright Yellow
        "Technology": "#29B6F6",            // Bright Cyan
        "Design & Technology": "#64B5F6"    // Sky Blue
    };
    return (
        <Card
            sx={{
                maxWidth: 345,
                width: '100%',
                marginBottom: 2,
                position: 'relative',
                overflow: 'hidden',
                height: '150px',
                borderRadius: '10px',
                cursor: available ? 'pointer' : 'not-allowed',
                transition: 'transform 0.2s ease-in-out',
                '&:hover': {
                    transform: available ? 'scale(1.02)' : 'none',
                },
                opacity: available ? 1 : 0.6
            }}
            onClick={() => available && navigate(`/resource-hub/${className}`)}
        >
            <Box sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundImage: `url(${backgrounds[className]})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                filter: 'blur(2px)',
                transform: 'scale(1.05)',
                overflow: 'hidden',
                '&::after': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.4)',
                }
            }} />
            <Box sx={{
                position: 'relative',
                zIndex: 1,
                color: 'white',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end'
            }}>
                <CardContent sx={{ 
                    padding: '16px !important',  // Consistent padding
                    paddingBottom: '16px !important'  // Ensure bottom padding
                }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1
                    }}>
                        <Avatar sx={{ bgcolor: subjectColors[className], height: 35, width: 35 }}>
                            <Icon />
                        </Avatar>
                        <Stack direction={'column'}>
                            <Typography variant={'h6'}>
                                <strong>{className}</strong>
                        </Typography>
                        <Typography variant={'body2'}>
                            {!available && <span style={{ color: 'white' }}>Coming Soon</span>}
                            </Typography>
                        </Stack>
                    </Box>
                </CardContent>
            </Box>
        </Card>
    );
};

export default SubjectCard; 