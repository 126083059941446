import React, {useContext, useRef, useState} from "react";
import {MainLayout} from "../../layouts/main-layout/MainLayout";
import {Context as AppContext} from "../../context/AppContext";
import {
    Avatar,
    Box,
    Button,
    Fade,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Stack,
    Tooltip,
    Typography
} from "@mui/material";
import {auth, db} from "../../config/firebase"
import {IconDots, IconPencil, IconTrash} from "@tabler/icons-react";
import {deepPurple, grey, orange, red} from "@mui/material/colors";
import moment from "moment";
import useConfirm from "../../hooks/useConfirm";
import {CreateIdeaModal} from "../../components/ideas/CreateIdeaModal";
import {deleteDoc, doc} from "firebase/firestore";
import {SimilarIdeas} from "./SimilarIdeas";
import FavoriteBorderIcon from '@mui/icons-material/Favorite';

export const QuestionsPage = () => {
    const {state: {ideas}, fetchIdeas, updateIdeas} = useContext(AppContext)
    const [selectIdea, setSelectIdea] = useState(null);
    const scrollableRef = useRef();

    function likeIdea(idea) {
        console.log('likeIdea', idea);
        ideas.forEach(ideaObject => {
            if (ideaObject.uid === idea.uid) {
                if (ideaObject.likes) {
                    ideaObject.likes.push(auth.currentUser.uid);
                } else ideaObject.likes = [auth.currentUser.uid];
            }
        })

        updateIdeas(ideas)
    }

    return (
        <MainLayout onClose={() => setSelectIdea(null)}>
            <Stack sx={{
                p: 1,
                display: "flex",
                flexDirection: {xs: 'column-reverse', md: "row"},
                maxHeight: "85vh",
                height: '100%',
                overflowY: {xs: "auto", md: "none"}
            }} gap={1}>
                <Stack ref={scrollableRef} direction={"column"} gap={2} className={"hidden-overflow"}
                       sx={{
                           flex: 2,
                           px: 1,
                           pb: 2,
                           overflowY: {xs: "none", md: "auto"},
                           display: selectIdea ? "none" : "flex"
                       }}>
                    {
                        ideas?.map(item => <QuestionItem key={item.uid} idea={item}
                                                         onSelectIdea={() => setSelectIdea(item)}
                                                         showButton={true}
                                                         likeIdea={likeIdea}
                                                         setSelectedIdea={setSelectIdea}
                                                         reloadIdeas={async () => await fetchIdeas()}/>)
                    }
                </Stack>

                {
                    selectIdea && <Fade in={true}>
                        <Stack direction={"column"} gap={2} className={"hidden-overflow"}
                               sx={{
                                   flex: 2,
                                   px: 1,
                                   pb: 2,
                                   overflowY: {xs: "none", md: "auto"},
                                   display: !selectIdea ? "none" : "flex"
                               }}>
                            <SimilarIdeas idea={selectIdea} onSelectIdea={(item) => setSelectIdea(item)}
                                          onClose={() => setSelectIdea(null)}/>
                        </Stack>
                    </Fade>
                }
                {/*<Box*/}
                {/*  sx={{*/}
                {/*    display: {xs: "none", lg: "flex"},*/}
                {/*    position: "sticky",*/}
                {/*    alignSelf: "flex-start",*/}
                {/*    flex: 1,*/}
                {/*    top: 0*/}
                {/*  }}>*/}
                {/*  <TagsMiniCard/>*/}
                {/*</Box>*/}
                {/*{!selectIdea &&*/}
                {/*    <Box*/}
                {/*        sx={{*/}
                {/*            display: {xs: "flex", lg: "none"},*/}
                {/*            p: 1,*/}
                {/*            // flex: 1*/}
                {/*        }}>*/}
                {/*        <TagsMiniCard/>*/}
                {/*    </Box>*/}
                {/*}*/}
            </Stack>

        </MainLayout>
    )
}

export const QuestionItem = ({idea, onSelectIdea, reloadIdeas, showButton, setSelectedIdea, likeIdea}) => {
    let color = "#FF0000";
    if (idea?.similarity > 75) {
        color = "#25D366"
    } else if (idea?.similarity > 50) {
        color = orange[700]
    }
    return (
        <Stack
            direction={"column"}
            gap={1}
            sx={{p: 2, bgcolor: "#FFF", borderRadius: 3, boxShadow: 3}}
        >
            <Stack direction={"row"} gap={1} alignItems={"center"}>
                <Avatar src={idea?.author?.photoURL || null} alt={idea?.author?.displayName[0]}/>
                <Box>
                    <Typography fontSize={17} fontWeight={600}>@{idea?.author?.displayName}</Typography>
                    <Typography fontSize={12} fontWeight={400}
                                sx={{color: grey[500]}}>{moment(idea?.createdAt).format("DD MMM YYYY HH:mm")}</Typography>
                </Box>
                {
                    idea?.similarity ?
                        <Typography color={color} variant={'h6'}
                                    fontWeight={800} sx={{ml: "auto"}}>
                            {idea?.similarity?.toFixed(0)}%
                        </Typography>
                        :
                        idea?.author?.uid === auth.currentUser?.uid &&
                        <SettingsButton idea={idea} reloadIdeas={reloadIdeas}/>
                }
            </Stack>
            <Typography onClick={onSelectIdea} sx={{cursor: "pointer", "&:hover": {color: deepPurple[700]}}}
                        variant={'h6'}
                        fontWeight={800}>{idea?.title}</Typography>
            <Typography fontWeight={500}>
                {idea?.description}
            </Typography>

            <Stack direction={"row"} gap={1} useFlexGap justifyContent={'space-between'} alignItems={"flex-end"}
                   alignContent={'flex-end'} sx={{textAlign: 'center'}}>

                <Stack direction={'row'}
                       sx={{textAlign: 'center'}}
                       variant={'outlined'} size={'small'}>
                    <IconButton onClick={() => likeIdea(idea)} sx={{padding: 0}}>
                        <FavoriteBorderIcon sx={{color: color}}/>
                    </IconButton>
                    <Typography sx={{paddingLeft: 1}}
                                variant={'body1'}>{idea.likes ? idea.likes.length : 0}</Typography>

                </Stack>

                {showButton &&
                    <Button
                        onClick={() => setSelectedIdea(idea)}
                        variant={'outlined'} size={'small'}>
                        Check similarity
                    </Button>
                }
                {/*    <Chip*/}
                {/*        variant={"filled"}*/}
                {/*        label={"AI"}*/}
                {/*    />*/}
                {/*    <Chip*/}
                {/*        variant={"filled"}*/}
                {/*        label={"LLM"}*/}
                {/*    />*/}
                {/*    <Chip*/}
                {/*        variant={"filled"}*/}
                {/*        label={"generativeai"}*/}
                {/*    />*/}
                {/*    <Chip*/}
                {/*        variant={"filled"}*/}
                {/*        label={"machinelearning"}*/}
                {/*    />*/}
            </Stack>
        </Stack>
    )
}

const SettingsButton = ({idea, reloadIdeas}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [Dialog, confirmDelete] = useConfirm(
        'Are you sure you want to delete this idea?',
        idea?.title || ""
    );
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Dialog/>
            <CreateIdeaModal open={isEditModalOpen} handleClose={() => setIsEditModalOpen(false)} editIdea={idea}/>
            <Tooltip title="Settings">
                <IconButton onClick={handleClick} sx={{ml: "auto"}}><IconDots/></IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                id="settings-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        minWidth: 150,
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
            >
                <MenuItem
                    onClick={async () => {
                        setIsEditModalOpen(true);
                        handleClose();
                    }}
                >
                    <ListItemIcon>
                        <IconPencil fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText primary={"Edit"}/>
                </MenuItem>
                <MenuItem
                    sx={{color: red[700]}}
                    onClick={async () => {
                        const response = await confirmDelete();
                        if (response) {
                            await deleteDoc(doc(db, "ideas", idea?.uid));
                            reloadIdeas();
                        }
                        handleClose();
                    }}
                >
                    <ListItemIcon>
                        <IconTrash fontSize="small" style={{color: red[700]}}/>
                    </ListItemIcon>
                    <ListItemText primary={"Delete"}/>
                </MenuItem>
            </Menu>
        </>
    )
}
