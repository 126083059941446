import React, { useContext } from "react";
import { Ideas } from "../../components/ideas/Ideas";
import { auth } from "../../config/firebase";
import { Context as AppContext } from "../../context/AppContext";
import { MainLayout } from "../../layouts/main-layout/MainLayout";

export const MyIdeas = () => {
  const {state: {ideas}} = useContext(AppContext);
  return (
    <MainLayout>

          <Ideas title={"Your ideas"} 
          ideas={ideas?.filter(idea => idea?.author?.uid === auth?.currentUser?.uid)} />
    </MainLayout>
  )
}