import React, {useContext, useEffect, useState} from 'react';
import {Box, IconButton, Stack, TextField, Typography} from "@mui/material";
import {MainLayout} from "../../layouts/main-layout/MainLayout";
import ChatWindow from "./ChatWindow";
import loadingGif from "../../assets/ai-loading.webm";
import SendIcon from "@mui/icons-material/Send";
import {Context as AppContext} from "../../context/AppContext";
import {toast} from "react-toastify";
import {httpsCallable} from "firebase/functions";
import {functions} from "../../config/firebase";
import ClassSelection from "./ClassSelection";
import {enums} from "../../utils/enums";
import {messagesCurriculum, messagesInternship} from "../../utils/enums";
import aidenSmall from "../../assets/small-logo.png";
import { keyframes } from '@mui/system';  // Import keyframes for animation

// Define the spin animation
const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const AiDesigner = () => {
    const {state: {messages, currentUser}, updateMessages} = useContext(AppContext)
    const getChatCompletion = httpsCallable(functions, "getChatCompletionC");
    const [isAnswering, setIsAnswering] = useState(true);
    const [userQuery, setUserQuery] = useState('');
    const [chatGptUserQuery, setChatGptUserQuery] = useState(null);
    const [selectedClass, setSelectedClass] = useState('');
    const [selectedGrade, setSelectedGrade] = useState('');
    const [getResponseFromChat, setGetResponseFromChat] = useState(0);
    const [isInternship, setIsInternship] = useState(currentUser.role === enums.MENTOR);

    useEffect(() => {
        updateMessages(isInternship ? messagesInternship : messagesCurriculum);
    }, [currentUser]);

    useEffect(() => {
        if (getResponseFromChat > 0 && (isInternship || (selectedClass && selectedGrade))) {
            sendChatGptPrompt();
        }
    }, [getResponseFromChat]);

    useEffect(() => {
        if (!chatGptUserQuery) return;

        // Add user's query to the message array before making the request
        messages.push(chatGptUserQuery);
        updateMessages([...messages])
        setUserQuery('');

        getMessages(); // Call the async function

    }, [chatGptUserQuery]);

    useEffect(() => {
        if (selectedClass && selectedGrade) {
            const userQuery = `I want to design a  ${selectedClass} class, for the following grade: ${selectedGrade}`
            setUserQuery(userQuery);
            setGetResponseFromChat(getResponseFromChat + 1);
        }
    }, [selectedClass, selectedGrade]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.ctrlKey && event.key === 'Enter') {
                setUserQuery(userQuery);
                setGetResponseFromChat(getResponseFromChat + 1);
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    async function getMessages() {
        try {
            setIsAnswering(true);

            const response = await getChatCompletion({messages: messages});

            // Firebase callable functions don't have a 'status' field, so check if response.data exists
            if (response.data) {
                messages.push(response.data.message); // Push the returned message to the array
                updateMessages([...messages])
                setChatGptUserQuery(null);
            } else {
                toast.error('Something went wrong, please try again!');
            }

        } catch (error) {
            console.error("Error fetching chat completion:", error);
            toast.error('An error occurred, please try again!');
        } finally {
            setIsAnswering(false); // Ensure this runs in both success and error cases
        }
    }

    function sendChatGptPrompt() {
        setChatGptUserQuery(
            {
                "role": "user",
                "content": userQuery
            }
        )
    }

    return (
        <MainLayout>
            <Box sx={{
                 height: 'calc(100% - 60px)',
                 overflow: 'hidden',
                 p: 3,
                 position: 'relative',
                 display: 'flex',
                 flexDirection: "column",
            }}>
               { !isInternship && 
                    <Stack 
                        direction={{ xs: 'column', sm: 'row' }} 
                        alignItems={{ xs: 'flex-start', sm: 'center' }}
                        justifyContent={{ xs: 'flex-start', sm: 'space-between' }}
                        spacing={2}
                    >
                        <Typography variant={'h3'}>
                            Curriculum Designer
                        </Typography>
                        <ClassSelection 
                            selectedClass={selectedClass} 
                            setSelectedClass={setSelectedClass}
                            selectedGrade={selectedGrade} 
                            setSelectedGrade={setSelectedGrade}
                        />
                    </Stack>
                }
                {isInternship && <Stack direction={'row'} alignItems={'center'}
                       justifyContent={"space-between"}
                >
                    <Typography variant={'h3'}  sx={{mr: 2}}>
                        Internship Designer
                    </Typography>
                </Stack>}

                <ChatWindow sx={{flex: 1}}/>
                
                <Stack>
                    {isAnswering && (
                        <img 
                            src={aidenSmall} 
                            alt="Aiden Assistant" 
                            style={{
                                width: '20px',
                                animation: `${spin} 2s linear infinite`  // Apply the spin animation
                            }}
                        />
                    )}
                    <Stack direction={'row'} gap={2} alignItems={'center'} justifyContent={'center'}
                       sx={{position: 'relative', bottom: 10, width: '90%', paddingLeft: "50px"}}>
                    { !isInternship && <TextField
                        placeholder={selectedGrade && selectedClass ? "Start typing..." : "Please select both class and grade to chat with Aiden"}
                        multiline rows={3} sx={{flex: 1,  backgroundColor: 'white'}}
                        value={userQuery} onChange={e => setUserQuery(e.target.value)}
                        disabled={!selectedClass || !selectedGrade}
                        // helperText={!selectedClass || !selectedGrade ? "Please select both class and grade to enable this field." : ""}
                    />}
                    {isInternship && <TextField
                        placeholder={"Start typing..."}
                        multiline rows={3} sx={{flex: 1,  backgroundColor: 'white'}}
                        value={userQuery} onChange={e => setUserQuery(e.target.value)}
                        // helperText={!selectedClass || !selectedGrade ? "Please select both class and grade to enable this field." : ""}
                    />}
                    <Box sx={{textAlign: "right"}}>
                        {
                            isAnswering ?
                                <video style={{width: '50px'}} autoPlay loop src={loadingGif}/> :
                                <IconButton color="primary" variant={"outlined"} sx={{width: "50px", height: "50px"}}
                                            aria-label="add"
                                            onClick={() => setGetResponseFromChat(getResponseFromChat + 1)}>
                                    <SendIcon/>
                                </IconButton>
                        }
                    </Box>
                    </Stack>
                </Stack>
            </Box>
        </MainLayout>
    );
};

export default AiDesigner;
