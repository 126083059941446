import { Box, Typography, List, ListItem, ListItemIcon, ListItemText, Paper } from "@mui/material";
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import QuestionMark from '@mui/icons-material/QuestionMark';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SchoolIcon from '@mui/icons-material/School';
import QuizIcon from '@mui/icons-material/Quiz';
import GroupIcon from '@mui/icons-material/Group';

const MasterPromptsContent = () => {

    return (
        <Box sx={{ mt: 4 }}>
            {/* Title Section */}
            <Box sx={{ mb: 6 }}>
                <Typography variant="h4" color="primary" gutterBottom>
                    Teacher AI Integration Guide
                </Typography>
                <Typography variant="body1" paragraph>
                    How to Make AI Use Impactful and Manageable in the Classroom
                </Typography>
            </Box>

            {/* Guide Sections */}
            <Box sx={{ mb: 6 }}>
                <Typography variant="h4" color="primary" gutterBottom>
                    1. Types of Prompts
                </Typography>
                <Typography variant="body1" paragraph>
                    Prompts are the key to unlocking the potential of AI. Use them thoughtfully to guide the AI's responses toward your teaching objectives.
                </Typography>
                <List>
                    <ListItem>
                        <ListItemIcon><CheckCircleOutline color="primary" /></ListItemIcon>
                        <ListItemText 
                            primary="Open-Ended Prompts"
                            secondary="Encourage creativity and exploration. Example: 'Create a story about a hero who learns a surprising lesson.'"
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><CheckCircleOutline color="primary" /></ListItemIcon>
                        <ListItemText 
                            primary="Structured Prompts"
                            secondary="Provide specific formats or constraints. Example: 'List 3 causes of deforestation and explain each in one sentence.'"
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><CheckCircleOutline color="primary" /></ListItemIcon>
                        <ListItemText 
                            primary="Analytical Prompts"
                            secondary="Foster critical thinking and reasoning. Example: 'Compare two animals based on their habitats. What advantages does each have?'"
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><CheckCircleOutline color="primary" /></ListItemIcon>
                        <ListItemText 
                            primary="Creative Prompts"
                            secondary="Inspire imagination and unconventional thinking. Example: 'If animals could talk, what laws would they create?'"
                        />
                    </ListItem>
                </List>
            </Box>

            <Box sx={{ mb: 6 }}>
                <Typography variant="h4" color="primary" gutterBottom>
                    2. Brainstorming with AI
                </Typography>
                <Typography variant="body1" paragraph>
                    Leverage AI to jumpstart class discussions or inspire new ideas:
                </Typography>
                <List>
                    <ListItem>
                        <ListItemIcon><SchoolIcon color="primary" /></ListItemIcon>
                        <ListItemText 
                            primary="Idea Starters"
                            secondary="Ask AI for diverse suggestions and refine them with students. Example: 'What are 5 fun ways to reduce plastic use at school?'"
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><GroupIcon color="primary" /></ListItemIcon>
                        <ListItemText 
                            primary="Class Activity"
                            secondary="Evaluate the feasibility of each idea as a group."
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><AssignmentIcon color="primary" /></ListItemIcon>
                        <ListItemText 
                            primary="Group Contributions"
                            secondary="Collaborate with students to co-create prompts. Example Activity: 'Let’s ask the AI to create a new holiday. What should we include in the prompt?'"
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><SchoolIcon color="primary" /></ListItemIcon>
                        <ListItemText 
                            primary="Mind Mapping"
                            secondary="Use AI to generate keywords or concepts around a topic. Prompt: 'What are some key words related to the water cycle?'"
                        />
                    </ListItem>
                </List>
            </Box>

            <Box sx={{ mb: 6 }}>
                <Typography variant="h4" color="primary" gutterBottom>
                    3. Creative Exploration
                </Typography>
                <Typography variant="body1" paragraph>
                    Encourage students to explore new perspectives with AI:
                </Typography>
                <List>
                    <ListItem>
                        <ListItemIcon><QuestionMark color="primary" /></ListItemIcon>
                        <ListItemText 
                            primary="Alternative Viewpoints"
                            secondary="Use AI to show contrasting perspectives. Prompt: 'What are the pros and cons of having pets in school?'"
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><AssignmentIcon color="primary" /></ListItemIcon>
                        <ListItemText 
                            primary="Story Building"
                            secondary="Co-create narratives with AI. Prompt: 'Write the first paragraph of a story about a magical tree. Let’s decide how it continues!'"
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><SchoolIcon color="primary" /></ListItemIcon>
                        <ListItemText 
                            primary="Role-Playing"
                            secondary="Use AI to simulate conversations with historical figures or characters. Prompt: 'Pretend to be a scientist from 100 years ago. What advice would you give to today’s students about the environment?'"
                        />
                    </ListItem>
                </List>
            </Box>

            <Box sx={{ mb: 6 }}>
                <Typography variant="h4" color="primary" gutterBottom>
                    4. Encouraging Critical Thinking
                </Typography>
                <Typography variant="body1" paragraph>
                    AI outputs are tools for discussion, not definitive answers. Help students engage critically:
                </Typography>
                <List>
                    <ListItem>
                        <ListItemIcon><QuizIcon color="primary" /></ListItemIcon>
                        <ListItemText 
                            primary="Evaluate Responses"
                            secondary="Ask: 'What do you agree with in this response? What seems incorrect or biased?' Activity: Compare the AI’s response to textbook information or real-world examples."
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><QuizIcon color="primary" /></ListItemIcon>
                        <ListItemText 
                            primary="Ask for Evidence"
                            secondary="Prompt: 'What evidence supports or contradicts this statement?'"
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><QuizIcon color="primary" /></ListItemIcon>
                        <ListItemText 
                            primary="Reflection Questions"
                            secondary="'How would you improve the AI’s answer?' 'What questions would you ask to clarify this response?'"
                        />
                    </ListItem>
                </List>
            </Box>

            <Box sx={{ mb: 6 }}>
                <Typography variant="h4" color="primary" gutterBottom>
                    5. Collaboration and Engagement
                </Typography>
                <Typography variant="body1" paragraph>
                    Use AI as a shared resource in the classroom:
                </Typography>
                <List>
                    <ListItem>
                        <ListItemIcon><GroupIcon color="primary" /></ListItemIcon>
                        <ListItemText 
                            primary="Shared Screen Discussions"
                            secondary="Display AI-generated content and analyze it as a group. Activity: Use the AI to summarize a lesson topic, then let students critique or add to it."
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><AssignmentIcon color="primary" /></ListItemIcon>
                        <ListItemText 
                            primary="Refining Prompts Together"
                            secondary="Example: 'How can we make this question clearer for the AI?'"
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><AssignmentIcon color="primary" /></ListItemIcon>
                        <ListItemText 
                            primary="Group Projects"
                            secondary="Use AI to assist in group tasks such as writing reports, brainstorming project ideas, or generating visuals."
                        />
                    </ListItem>
                </List>
            </Box>

            <Box sx={{ mb: 6 }}>
                <Typography variant="h4" color="primary" gutterBottom>
                    6. Research and Validation
                </Typography>
                <Typography variant="body1" paragraph>
                    Teach students how to validate AI-generated information:
                </Typography>
                <List>
                    <ListItem>
                        <ListItemIcon><QuizIcon color="primary" /></ListItemIcon>
                        <ListItemText 
                            primary="Cross-Check Facts"
                            secondary="Activity: Compare AI output with trusted sources to determine accuracy. Prompt: 'Explain the importance of honeybees in 3 sentences.'"
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><AssignmentIcon color="primary" /></ListItemIcon>
                        <ListItemText 
                            primary="Encourage Real-World Application"
                            secondary="Example: 'AI suggested these ways to save water. Which ones can we implement at school?'"
                        />
                    </ListItem>
                </List>
            </Box>

            <Box sx={{ mb: 6 }}>
                <Typography variant="h4" color="primary" gutterBottom>
                    7. Time-Saving AI Use for Teachers
                </Typography>
                <Typography variant="body1" paragraph>
                    Streamline your teaching workflow with AI:
                </Typography>
                <List>
                    <ListItem>
                        <ListItemIcon><AssignmentIcon color="primary" /></ListItemIcon>
                        <ListItemText 
                            primary="Lesson Planning"
                            secondary="Prompt: 'Create a lesson plan for teaching fractions to 4th graders.'"
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><AssignmentIcon color="primary" /></ListItemIcon>
                        <ListItemText 
                            primary="Classroom Materials"
                            secondary="Prompt: 'Design a fun worksheet on synonyms for 3rd grade.'"
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><AssignmentIcon color="primary" /></ListItemIcon>
                        <ListItemText 
                            primary="Assessment Ideas"
                            secondary="Prompt: 'Suggest 3 creative ways to assess students’ understanding of plant life cycles.'"
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><AssignmentIcon color="primary" /></ListItemIcon>
                        <ListItemText 
                            primary="Differentiated Instruction"
                            secondary="Prompt: 'How can I adapt this activity for students who need extra help?'"
                        />
                    </ListItem>
                </List>
            </Box>

            <Box sx={{ mb: 6 }}>
                <Typography variant="h4" color="primary" gutterBottom>
                    8. Safety and Ethics
                </Typography>
                <Typography variant="body1" paragraph>
                    Teach responsible AI use:
                </Typography>
                <List>
                    <ListItem>
                        <ListItemIcon><QuestionMark color="primary" /></ListItemIcon>
                        <ListItemText 
                            primary="Age-Appropriate Outputs"
                            secondary="Use filters and monitor AI interactions to ensure content is suitable."
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><QuestionMark color="primary" /></ListItemIcon>
                        <ListItemText 
                            primary="Privacy Awareness"
                            secondary="Discuss the importance of not sharing personal information with AI tools."
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><QuestionMark color="primary" /></ListItemIcon>
                        <ListItemText 
                            primary="Ethical Use"
                            secondary="Activity: Debate when it’s fair to use AI versus doing tasks on our own."
                        />
                    </ListItem>
                </List>
            </Box>

            <Box sx={{ mb: 6 }}>
                <Typography variant="h4" color="primary" gutterBottom>
                    Example Classroom Activities
                </Typography>
                <List>
                    <ListItem>
                        <ListItemIcon><AssignmentIcon color="primary" /></ListItemIcon>
                        <ListItemText 
                            primary="AI as a Scientist"
                            secondary="Prompt: 'What are 3 fun experiments to learn about gravity?' Activity: Let students try one of the suggestions and evaluate its success."
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><AssignmentIcon color="primary" /></ListItemIcon>
                        <ListItemText 
                            primary="AI Story Co-Writing"
                            secondary="Prompt: 'Write a story about a spaceship that lands in our school.' Activity: Students add to the story, one sentence at a time."
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><AssignmentIcon color="primary" /></ListItemIcon>
                        <ListItemText 
                            primary="AI Debate Prep"
                            secondary="Prompt: 'What are arguments for and against school uniforms?' Activity: Organize a class debate using AI-generated points."
                        />
                    </ListItem>
                </List>
            </Box>

            <Box sx={{ mb: 6 }}>
                <Typography variant="h4" color="primary" gutterBottom>
                    Getting Started
                </Typography>
                <Typography variant="body1" paragraph>
                    Identify 1-2 activities in your next lesson where AI could add value. Experiment with simple prompts and involve students in refining them. Celebrate mistakes and discoveries together—it’s part of learning with new technology.
                </Typography>
                <Typography variant="body1" paragraph>
                    This guide provides a framework for meaningful AI integration in primary classrooms. Remember, AI is not a replacement but a tool to amplify teaching and foster creativity and critical thinking. Have fun exploring!
                </Typography>
            </Box>
        </Box>
    );
};

export default MasterPromptsContent;
