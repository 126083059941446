import React, {useContext, useEffect} from 'react';
import {Button, Card, CardActions, CardContent, CardMedia, Grid, Stack, Typography, IconButton} from "@mui/material";
import Box from "@mui/material/Box";
import './mentorHomepage.css';
import {toast} from "react-toastify";
import {Context as AppContext} from "../../context/AppContext";
import cardBackground1 from '../../assets/c-background1.jpg'
import cardBackground2 from '../../assets/c-background2.jpg'
import cardBackground3 from '../../assets/c-background3.jpg'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import {truncateWords, getImageUrl} from '../../utils/functions';

const ListOfMentors = ({mentors}) => {
    const {state: {currentUser}, updateUserData} = useContext(AppContext);

    function applyForMentorship(mentorUid) {
        currentUser.hasApplied = true
        currentUser.mentorApplications = currentUser.mentorApplications ? [...currentUser.mentorApplications, mentorUid] : [mentorUid]
        updateUserData(currentUser)
        toast.success("You have applied!")
        console.log('Applying for mentorship')
    }

    function openMentorProfile(mentorUid) {
        // navigate(`/mentor/${mentorUid}`)
    }

    
    function getApplicationStatus(uid) {
        if (currentUser?.hasApplied) {
            return "Applied"
        } else if (currentUser?.mentorUid === uid) {
            return <span className={'white-text'}>Applied </span>
        } else {
            return <span className={'white-text'}>Apply </span>
        }
    }

    

    return (
        <Box sx={{p: 2}}>

            <Grid container direction={'row'}>
                {mentors.map((mentor, index) => {
                    const backgrounds = [cardBackground1, cardBackground2, cardBackground3];
                    const backgroundImage = backgrounds[index % 3]; // Cycles through 0,1,2,0,1,2,...
                    return (
                        <Grid item xs={12} sm={6}>
                            <Card key={mentor.uid} sx={{
                                maxWidth: 345, 
                                marginBottom: 2,
                                position: 'relative',
                                overflow: 'hidden',
                                height: '300px',
                                minHeight: '300px',
                                borderRadius: '10px'
                            }}>
                                <Box sx={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    backgroundImage: `url(${backgroundImage})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    filter: 'blur(5px)',
                                    transform: 'scale(1.1)',
                                    '&::after': {
                                        content: '""',
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        backgroundColor: 'rgba(0, 0, 0, 0.4)', // Adjust opacity as needed
                                    }
                                }} />
                                <Box sx={{ 
                                    position: 'relative', 
                                    zIndex: 1,
                                    color: 'white'  // Optional: make all text white for better contrast
                                }}>
                                    <CardMedia
                                        component="img"
                                        height="100"
                                        style={{objectFit: 'contain', padding: 1}}
                                        image={getImageUrl(mentor.company)}
                                        alt={mentor.name}
                                    />
                                    <CardContent sx={{minHeight: '100px'}}>
                                        <Typography variant={'h5'}><strong>{mentor.name.replace(/\b\w/g, char => char.toUpperCase())}</strong></Typography>
                                        <Typography sx={{mb: 1}} variant={'body1'}>{mentor.jobTitle.replace(/\b\w/g, char => char.toUpperCase())}</Typography>
                                        <Typography variant={'body1'}>
                                            {truncateWords(mentor.jobDescription, 10)}
                                        </Typography>
                                    </CardContent>
                                    <CardActions sx={{p: 2}}>
                                        <Button size="small" variant={'contained'}
                                                disabled={currentUser?.hasApplied}
                                                onClick={() => applyForMentorship(mentor.uid)}>
                                            {getApplicationStatus(mentor.uid)}
                                        </Button>
                                        {mentor.linkedIn && (
                                            <IconButton 
                                                href={mentor.linkedIn}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                sx={{ color: 'white' }}
                                            >
                                                <LinkedInIcon />
                                            </IconButton>
                                        )}
                                    </CardActions>
                                </Box>
                            </Card>
                        </Grid>
                    )
                })}
            </Grid>
        </Box>
    );
};

export default ListOfMentors;
