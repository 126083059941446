import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Grid, Modal, Stack, TextField, Typography, CardActions, IconButton } from "@mui/material";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { auth } from '../../config/firebase';
import { Context as AppContext } from "../../context/AppContext";
import { MainLayout } from "../../layouts/main-layout/MainLayout";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import GptModal from '../../components/GptModal';
import '../../index.css';
import Lottie from 'lottie-react';
import animationData from '../../assets/aiden boat.json';

const ClassGpt = () => {
    const navigate = useNavigate();
    const {state: {GPTS}, createGPT, fetchGPTs, deleteGPT} = useContext(AppContext)
    const [openModal, setOpenModal] = useState(false);
    const [assistantBehavior, setAssistantBehavior] = useState('');
    const [assistantTitle, setAssistantTitle] = useState('');
    const [editingGpt, setEditingGpt] = useState(null);

    useEffect(() => {
        fetchGPTs()
        console.log(GPTS)
    }, [])
    const handleEdit = () => {
        setOpenModal(true);
    };

    const handleDelete = (uid) => {
        if (window.confirm('Are you sure you want to delete this GPT?')) {
            deleteGPT(uid);
            navigate('/class-gpt');
        }
    };
    const handleOpen = () => setOpenModal(true);
    const handleClose = () => setOpenModal(false);
    const handleCreateAssistant = () => {
        createGPT({
            title: assistantTitle,
            description: assistantBehavior,
            uid: uuidv4(),
            createdBy: auth.currentUser.uid,
            createdAt: new Date().getTime()
        })
        setOpenModal(false)
        console.log(assistantTitle, assistantBehavior);
    }
    return (
        <MainLayout>
            <Box sx={{
                height: '80vh',
                p: 3,
                position: 'relative',
                display: 'flex',
                flexDirection: "column",
                overflow: 'auto',
                '&::-webkit-scrollbar': {
                    width: '8px',
                    background: 'transparent'
                },
                '&::-webkit-scrollbar-thumb': {
                    background: '#bfbfbf',
                    borderRadius: '4px',
                    '&:hover': {
                        background: '#a6a6a6'
                    }
                },
                '&::-webkit-scrollbar-track': {
                    background: 'transparent'
                }
            }}>
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    alignItems={{ xs: 'flex-start', sm: 'center' }}
                    spacing={2}
                >
                    <Typography variant={'h3'}>
                        {'In-Class GPT'}
                    </Typography>
                </Stack>

                {GPTS?.length > 0 ? (
                <Grid 
                    container 
                    spacing={2}
                    sx={{ mt: 4, width: '100%', maxWidth: '900px', mx: 'auto' }} // Center the grid and limit max width
                    alignItems={'center'} 
                    justifyContent={'center'}
                >
                    {GPTS?.map((gpt, index) => (
                        <Grid 
                            item 
                            xs={12} 
                            sm={6} 
                            key={index} 
                            sx={{ 
                                display: 'flex', 
                                justifyContent: 'center', 
                                alignItems: 'center',
                            }}
                        >
                            <Card 
                                onClick={() => navigate(`/class-gpt/${gpt.uid}`)}
                                sx={{ 
                                    width: '100%',
                                    height: '100%',
                                    minHeight: 200,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    transition: 'transform 0.2s, box-shadow 0.2s',
                                    '&:hover': {                                        
                                        boxShadow: (theme) => theme.shadows[8],
                                    }
                                }}
                            >
                                <CardContent sx={{ flex: 1, p: 3 }}>
                                    <Box 
                                        sx={{ 
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            mb: 2 
                                        }}
                                    >
                                        <Typography 
                                            variant="h5" 
                                            color="textPrimary"
                                        >
                                           <span className="rainbow-text">{gpt.title}</span>
                                        </Typography>
                                    </Box>
                                    <Typography variant={'body1'}>{gpt.description}</Typography>
                                </CardContent>
                                <CardActions sx={{ 
                                    justifyContent: 'space-between', 
                                    p: 1,
                                    display: 'flex' 
                                }}>
                                    <div>  {/* Left side */}
                                        <IconButton 
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                navigate(`/class-gpt/${gpt.uid}/chat`);
                                            }}
                                            color="primary"
                                        >
                                            <PlayArrowIcon />
                                        </IconButton>
                                    </div>
                                    <div>  {/* Right side */}
                                        <IconButton onClick={(e) => {
                                            e.stopPropagation();
                                            handleEdit(gpt);
                                        }}>
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton onClick={(e) => {
                                            e.stopPropagation();
                                            handleDelete(gpt.uid);
                                        }}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </div>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
                ) : (
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                                <Lottie animationData={animationData} loop={true} style={{ height: '400px', width: '400px' }} />
                                <Typography variant={'h5'}>Create a GPT to get started</Typography>
                    </Box>
                )}

                <Button
                    variant="contained"
                    onClick={handleOpen}
                    sx={{
                        position: 'fixed',
                        bottom: "5%",
                        right: "5%",
                        background: 'linear-gradient(90deg, #4c81f2, #a670bd, #a670bd)',
                        color: 'white',
                        animation: 'pulse 2s infinite',
                        '@keyframes pulse': {
                            '0%': {
                                transform: 'scale(1)',
                                boxShadow: '0 0 0 0 rgba(76, 129, 242, 0.4)'
                            },
                            '70%': {
                                transform: 'scale(1.05)',
                                boxShadow: '0 0 0 10px rgba(76, 129, 242, 0)'
                            },
                            '100%': {
                                transform: 'scale(1)',
                                boxShadow: '0 0 0 0 rgba(76, 129, 242, 0)'
                            }
                        },
                        '&:hover': {
                            background: 'linear-gradient(90deg, #4c81f2, #a670bd, #a670bd)',
                            opacity: 0.9,
                            animation: 'none'
                        }
                    }}
                    startIcon={<AddIcon />}
                >
                    Create GPT
                </Button>

                <GptModal 
                    open={openModal}
                    onClose={handleClose}
                    onSubmit={handleCreateAssistant}
                    assistantTitle={assistantTitle}
                    setAssistantTitle={setAssistantTitle}
                    assistantBehavior={assistantBehavior}
                    setAssistantBehavior={setAssistantBehavior}
                    isEditing={!!editingGpt}        
                />
            </Box>  
        </MainLayout>
    );
};


export default ClassGpt;