import { Grid, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import {
    IconBallFootball, // History
    IconBook, // Science
    IconBuildingArch, // Music
    IconDeviceDesktop, // Computer Science
    IconDeviceLaptop,
    IconFlask,
    IconFolder, // Design & Technology
    IconMask, // Drama
    IconMath, // PE
    IconMicrophone2, // English
    IconPalette, // Technology
    IconRobot, // Coding & Robotics
    IconTools
} from '@tabler/icons-react';
import React from 'react';
import SubjectCard from '../../components/SubjectCard';
import { MainLayout } from "../../layouts/main-layout/MainLayout";
import { classes } from "../../utils/enums";


const ResourceHub = () => {
    const deduceIcon = (title) => {
        switch (title) {
            case "English": return IconBook;
            case "Math": return IconMath;
            case "Computer Science": return IconDeviceDesktop;
            case "Science": return IconFlask;
            case "Drama": return IconMask;
            case "History": return IconBuildingArch;
            case "Art": return IconPalette;
            case "Design & Technology": return IconTools;
            case "Physical Education": return IconBallFootball;
            case "Music": return IconMicrophone2;
            case "Technology": return IconDeviceLaptop;
            case "Coding & Robotics": return IconRobot;
            default: return IconFolder;
        }
    };



    return (
        <MainLayout>
            <Box sx={{
                height: '80vh',
                p: 3,
                position: 'relative',
                display: 'flex',
                flexDirection: "column",
                overflow: 'auto',
                '&::-webkit-scrollbar': {
                    width: '8px',
                    background: 'transparent'
                },
                '&::-webkit-scrollbar-thumb': {
                    background: '#bfbfbf',
                    borderRadius: '4px',
                    '&:hover': {
                        background: '#a6a6a6'
                    }
                },
                '&::-webkit-scrollbar-track': {
                    background: 'transparent'
                }
            }}>
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    alignItems={{ xs: 'flex-start', sm: 'center' }}
                    spacing={2}
                >
                    <Typography variant={'h3'}>
                        {'Resource Hub'}
                    </Typography>
                </Stack>

                <Grid 
                    container 
                    spacing={2}
                    sx={{ mt: 4, width: '100%', maxWidth: '900px', mx: 'auto' }} // Center the grid and limit max width
                    alignItems={'center'} 
                    justifyContent={'center'}
                >
                    {classes.map((c, index) => (
                        <Grid 
                            item 
                            xs={12} 
                            sm={6} 
                            key={index} 
                            sx={{ 
                                display: 'flex', 
                                justifyContent: 'center', 
                                alignItems: 'center',
                            }}
                        >
                            <SubjectCard className={c.name} index={index} Icon={deduceIcon(c.name)} available={c.available} />
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </MainLayout>
    );
};

export default ResourceHub;

