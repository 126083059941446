import React, {useContext, useEffect, useState} from "react";
import {Context as AppContext} from "../../context/AppContext";
import {IconButton, Stack, Typography} from "@mui/material";
import {QuestionItem} from "./QuestionsPage";
import {IconX} from "@tabler/icons-react";
import {MainLayout} from "../../layouts/main-layout/MainLayout.js";

export const SimilarIdeas = ({idea, onSelectIdea, onClose}) => {
    const {state: {ideas}, fetchIdeas} = useContext(AppContext);
    const [similarIdeas, setSimilarIdeas] = useState([]);

    useEffect(() => {
        console.log("ideas", ideas);
        if (ideas?.length) {
            const data = [...ideas];
            if (idea) {
                let similarIdeasItems = [];
                if (idea?.similarityToOthers?.length > 0) {
                    for (const item of data) {
                        const similar = idea?.similarityToOthers?.find(sim => sim.uid === item.uid);
                        if (similar) {
                            similarIdeasItems.push({...item, similarity: similar?.similarity});
                        }
                    }
                }
                similarIdeasItems = similarIdeasItems.sort((a, b) => b.similarity - a.similarity);
                console.log("similarIdeasItems", similarIdeasItems);
                setSimilarIdeas(similarIdeasItems);
            }
        }
    }, [idea, ideas])

    return (
        <MainLayout>
            <Stack direction={"row"} justifyContent={"space-between"} alginItems={"center"}>
                <Typography
                    fontSize={23}
                    fontWeight={700}
                >
                    Selected idea:
                </Typography>
                <IconButton onClick={onClose}>
                    <IconX/>
                </IconButton>
            </Stack>
            <QuestionItem idea={idea} onSelectIdea={() => onSelectIdea(idea)}
                          reloadIdeas={async () => await fetchIdeas()}/>
            <Typography
                fontSize={20}
                fontWeight={700}
                align={"left"}
            >
                Similar ideas:
            </Typography>
            {
                similarIdeas?.map(item => <QuestionItem key={item?.uid} idea={item}
                                                        onSelectIdea={() => onSelectIdea(item)}
                                                        reloadIdeas={async () => await fetchIdeas()}/>)
            }
            {
                similarIdeas?.length === 0 &&
                <Typography fontSize={16}>There are no similar ideas found, please check back when more ideas have been
                    added to
                    the portal</Typography>
            }
        </MainLayout>
    )
}