import { Box, Stack, Typography } from "@mui/material";
import React, { useContext } from 'react';
import { Context as AppContext } from "../../context/AppContext";
import { MainLayout } from "../../layouts/main-layout/MainLayout";
import ListOfMentors from "./ListOfMentors";
import './mentorHomepage.css';

const MentorHomepage = () => {
    const {state: {currentUser, mentors}, updateUserData, getMentors} = useContext(AppContext);

    return (
        <MainLayout>
            <Box sx={{p: 5}}>
                <Stack gap={1}>
                    {mentors.length > 0
                        ? <ListOfMentors mentors={mentors}/>
                        : <Typography variant={'h5'}>No mentors are here yet in this cycle...</Typography>
                    }
                </Stack>
            </Box>
        </MainLayout>
    );
};

export default MentorHomepage;
