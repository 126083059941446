import React, { useContext, useState } from "react";
import {
    Avatar,
    Box,
    Button,
    IconButton,
    InputAdornment,
    ListItemIcon,
    Menu,
    MenuItem,
    Stack,
    TextField,
    Toolbar,
    Tooltip
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { signOut } from "firebase/auth";
import { auth } from "../../config/firebase";
import { IconLogout, IconMenu, IconPlus, IconSearch, IconUser } from "@tabler/icons-react";
import logo from "../../assets/aiden-logo.png";
import { CreateIdeaModal } from "../../components/ideas/CreateIdeaModal";
import { useNavigate } from "react-router-dom";
import { enums } from "../../utils/enums";
import { Context as AppContext } from "../../context/AppContext";

export const Header = ({ onToggle }) => {
    const { state: { currentUser } } = useContext(AppContext)
    return (
        <Toolbar sx={{  gap: 1, borderBottom: 1, borderColor: "divider", display: 'flex', justifyContent: 'space-between' }}>
            <ToggleMenu onToggle={onToggle} />
            {currentUser.role === enums.ADMIN &&
                <>
                    <TextField
                        placeholder={"Search"}
                        size={"small"}
                        sx={{ ml: "auto" }}
                        InputProps={{
                            sx: { borderRadius: 5, bgcolor: grey[100] },
                            startAdornment: <InputAdornment position={"start"}>
                                <IconSearch size={15} color={grey[500]} />
                            </InputAdornment>
                        }}
                    />
                    <Stack
                        direction={"row"}
                        gap={1}
                        sx={{
                            display: { xs: "none", md: "flex" }
                        }}
                    >
                        <ShareThought />
                    </Stack>
                </>
            }

            {/*<IconButton>*/}
            {/*    <Badge color="primary" variant="dot">*/}
            {/*        <IconBell/>*/}
            {/*    </Badge>*/}
            {/*</IconButton>*/}
            <Stack direction={"row"}
                gap={1}
                sx={{
                    display: { xs: "none", md: "flex" }
                }}>
                <UserAvatar />
            </Stack>
        </Toolbar>
    )
}

export const ShareThought = ({ fullWidth = false }) => {
    const [open, setOpen] = useState(false);
    return (
        <>
            <CreateIdeaModal
                open={open}
                handleClose={() => setOpen(false)}
            />
            {/* <Button
                variant={"contained"}
                color={"warning"}
                onClick={() => setOpen(true)}
                fullWidth={fullWidth}
                startIcon={<IconPlus size={15} />}
                sx={{
                    borderRadius: 2,
                    textTransform: "none",
                    backgroundColor: "linear-gradient(90deg, #4c81f2, #a670bd, #a670bd)",
                    background: "linear-gradient(90deg, #4c81f2, #a670bd, #a670bd)",
                }}
            >
                Share a thought
            </Button> */}
        </>
    )
}

export const ToggleMenu = ({ onToggle }) => {
    const navigate = useNavigate()
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
                onClick={onToggle}
                sx={{ display: { xs: "block", md: "none" } }}
            >
                <IconMenu />
            </IconButton>
            <Box
                component="img"
                src={logo}
                alt="Logo"
                height={20}
                sx={{
                    cursor: 'pointer',
                    display: { xs: "none", md:'block', xl: "block" }
                }}
                onClick={() => navigate('/')}
            />
        </Box>
    )
}

const UserAvatar = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate()
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <Box>
            <Tooltip title="Account settings">
                <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    <Avatar sx={{ 
                        width: 35, 
                        height: 35,
                        background: 'linear-gradient(90deg, #4c81f2, #a670bd, #a670bd)',
                        color: 'white'  // Ensure text remains visible
                    }}>
                        {auth.currentUser?.email?.slice(0, 2).toUpperCase()}
                    </Avatar> </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={async () => {
                    navigate('/profile')
                    handleClose();
                }}>
                    <ListItemIcon>
                        <IconUser fontSize={'small'} />
                    </ListItemIcon>
                    Profile
                </MenuItem>
                <MenuItem onClick={async () => {
                    console.log('signing out')
                    await signOut(auth);
                    navigate('/login')
                    handleClose();
                }}>
                    <ListItemIcon>
                        <IconLogout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </Box>
    )
}
