import React, {useContext} from 'react';
import {Box} from "@mui/material";
import {Context as AppContext} from "../../context/AppContext";
import {enums} from "../../utils/enums";

const Homepage = () => {
    const {state: {currentUser}} = useContext(AppContext);

    if (currentUser.role === enums.MENTOR) {
        return (
            <Box>

            </Box>
        )
    } else if (currentUser.role === enums.STUDENT) {
        return (
            <Box>

            </Box>
        )
    }
};

export default Homepage;
