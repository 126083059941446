import React, {useCallback, useContext, useRef} from 'react';
import {Stack, Typography} from "@mui/material";
import styled from 'styled-components';
import ellaLogo from '../../assets/small-logo.png'
import personIcon1 from '../../assets/person-icon.png'
import loadingGif from '../../assets/loading.webp'
import Box from "@mui/material/Box";
import useImagePreloader from "../../utils/usePreloadImage";
import {useDidUpdate} from "../../utils/useDidUpdate";
import {Context as AppContext} from "../../context/AppContext";
import Fade from '@mui/material/Fade';

const ChatBubble = styled.div`
  display: flex;
  flex-direction: row; /* Row orientation */
  align-items: flex-start; /* Align children vertically center */
  max-width: 100%;
  color: ${({role}) => (role === 'user' ? '#3498db' : '#2ecc71')};
  padding-bottom: 20px;
  white-space: pre-line;
`;


const ChatWindow = () => {
    const bottomRef = useRef(null);
    const {state: {messages}} = useContext(AppContext)
    

    useImagePreloader([ellaLogo, personIcon1, loadingGif])

    useDidUpdate(() => {
        if (bottomRef.current && messages.length > 0) {
            bottomRef.current.scrollIntoView({behavior: 'smooth'});
        }
    }, [messages]);


    function renderBoldText(text) {
        // Regular expression to match words wrapped in ** (e.g., **word**)
        const regex = /\*\*(.*?)\*\*/g;

        // Replace matched patterns with <strong> tags
        const boldText = text.replace(regex, '<strong>$1</strong>');

        return <span dangerouslySetInnerHTML={{__html: boldText}}/>;
    }

    const getMessageCard = useCallback((message) => {
        switch (message.role) {
            case 'user':
                return (
                    <Fade in={true} key={message.content}>
                        <ChatBubble role={message.role} key={message.content}>
                        <img src={personIcon1} style={{height: '40px', paddingRight: 10, paddingLeft: 2}}/>
                        <Typography variant={'body1'}
                                    sx={{
                                        whiteSpace: 'pre-line',
                                        color: '#7b7b7b',
                                        fontSize: '0.9rem'
                                    }}>{message.content}</Typography>
                        </ChatBubble>
                    </Fade>
                )
            case 'assistant':
                return (
                    <Fade in={true} timeout={900} key={message.content}>
                        <ChatBubble role={message.role} key={message.content}>
                            <img src={ellaLogo} style={{height: '40px', paddingRight: 10}}/>
                            <Typography variant={'body1'}
                                    sx={{
                                        color: '#505d8b',
                                        fontSize: '0.9rem'
                                    }}>{renderBoldText(message.content)}</Typography>
                        </ChatBubble>
                    </Fade>
                )
            default:
                console.log('default')
        }
    }, [messages])


    return (
        <Stack direction={"column"} gap={3} justifyContent={"space-between"}
               sx={{
                   flex: 1,
                   width: '90%',
                   paddingRight: '50px',
                   mt:2,
                   mx: {xs: 2, md: "auto"}, overflowY: "scroll",
                   '::-webkit-scrollbar': {
                       width: '12px',
                   },
                   '::-webkit-scrollbar-track': {
                       background: '#f1f1f1',
                       borderRadius: '10px',
                   },
                   '::-webkit-scrollbar-thumb': {
                       background: '#888',
                       borderRadius: '10px',
                   },
                   '::-webkit-scrollbar-thumb:hover': {
                       background: '#555',
                   }

               }}>
            <Stack direction={'column'}>
                {messages.map(message => {
                    return (
                            getMessageCard(message)
                    )
                })}
                <Box ref={bottomRef}/>
            </Stack>
        </Stack>
    );
}
export default ChatWindow;


