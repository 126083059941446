import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Stack, Typography } from "@mui/material";
import { Context as AppContext } from "../../context/AppContext";
import { MainLayout } from "../../layouts/main-layout/MainLayout";
import { enums } from "../../utils/enums";
import { useNavigate } from "react-router-dom";
import './mentorHomepage.css'
import animationData from '../../assets/student.json'; // Adjust the path to your Lottie animation file
import Lottie from "lottie-react";
import InternshipCard from '../../components/InternshipCard';

const InternHomepage = () => {
    const { state: { currentUser, mentors, internships }, getMentors, getInternships } = useContext(AppContext);
    const [internship, setInternship] = useState(null)

    const navigate = useNavigate()
    useEffect(() => {
        if (currentUser) {
            getMentors()
            const internshipUid = currentUser?.internshipUid
            console.log(internshipUid);
            if (internshipUid) {
                getInternships({uid: currentUser.uid, type: currentUser.role})
            }
        }
    }, [currentUser])

    useEffect(() => {
        console.log('internships');
        console.log(internships);
        
        if (internships?.length > 0) {
            setInternship(internships[0])
        }
    }, [internships])

    if ([enums.ADMIN, enums.STUDENT, enums.TEACHER].includes(currentUser?.role)) {
        navigate('/')    
    }
    const capitalizeFirstWord = (str) => {
        if (!str) return '';
        const firstWord = str.split(' ')[0];
        return firstWord.charAt(0).toUpperCase() + firstWord.slice(1).toLowerCase();
    };


    return (
        <MainLayout>
            <Box sx={{ p: 5, height: '70%' }}>
                <Typography variant={'h2'}>Welcome,
                    <span className={'rainbow-text'}>    {capitalizeFirstWord(currentUser?.name)} </span>
                </Typography>
                {!currentUser?.internshipUid ?
                    <Stack gap={2} sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', mt: 5, alignItems: 'center' }}>
                        <Typography variant={'h5'}>You don' have a mentor yet...</Typography>


                        <Lottie animationData={animationData} loop={true} style={{ maxHeight: '40%', maxWidth: '40%' }} />


                        <Stack direction={'column'} alignItems={'center'} gap={1} sx={{ mt: 2 }}>
                            {!currentUser?.hasApplied ? <Button sx={{ maxWidth: '300px' }} variant={'outlined'} 
                            disabled={currentUser?.hasApplied}
                            onClick={() => navigate('/mentors')}>
                                <span className="rainbow-text">{"Apply for an internship"}</span>
                            </Button>
                            :
                            <Stack direction={'column'} alignItems={'center'} gap={1} sx={{ mt: 2 }}>
                                <Typography variant={'body1'}>Awaiting response from</Typography>   
                                <Typography variant={'h5'}>{mentors?.find(mentor => mentor?.uid === currentUser?.mentorApplications[0])?.name}</Typography>
                            </Stack>    
                            }
                        </Stack>
                    </Stack>
                    :
                    <Box sx={{ mt: 5 }}>
                        <Typography variant={'h3'} sx={{ mb: 2 }}>Internships</Typography>
                        <InternshipCard intern={currentUser} index={0} />
                    </Box>
                }
            </Box>
        </MainLayout>

    )
        ;
};

export default InternHomepage;
