import { ArrowForwardIos, Circle } from "@mui/icons-material";
import { Box, Button, Divider, List, ListItem, ListItemText, Stack, TextField, Typography } from "@mui/material";
import { green, grey, lightBlue, orange, yellow } from "@mui/material/colors";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import background from "../../assets/milky-way.png";
import { MiniHeader } from "../../components/header/MiniHeader";
import { auth, db, functions } from "../../config/firebase";
import { enums } from "../../utils/enums";
import { useTheme } from "@mui/material/styles";

export const RegisterPageMentor = () => {
    const [email, setEmail] = useState("");
    const [linkedin, setLinkedin] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [company, setCompany] = useState('')
    const [location, setLocation] = useState('Dubai')
    const [jobDescription, setJobDescription] = useState('')
    const [jobTitle, setJobTitle] = useState('')
    const [name, setName] = useState('')
    const navigate = useNavigate()
    const linkedinAuth = httpsCallable(functions, "authLinkedIn");
    const theme = useTheme();
    let { companyName } = useParams();

    useEffect(() => {
        if (companyName) {

            if (!process.env.REACT_APP_PARTNERS.includes(companyName)) {
                toast.warning("Your company wasn't found, please contact us to become a partner.\n Redirecting you to contact form now.", {
                    autoClose: 5000,
                    position: "top-center"
                });
                setTimeout(() => {
                    window.location.href = 'https://www.aiden.global/contact';
                }, 5000);
            }

            setCompany(companyName)

        }
    }, [])

    const signUp = async () => {
        if (!email || !password || !confirmPassword || !company || !jobDescription || !jobTitle || !name) {
            toast.error("All fields are required!");
            return;
        }

        if (password !== confirmPassword) {
            toast.error("Password do not match");
            return;
        }
        try {
            const user = await createUserWithEmailAndPassword(auth, email, password);
            console.log(user)
            await setDoc(doc(db, 'users', user.user.uid), {
                name: name,
                email: email,
                company: company,
                jobDescription: jobDescription,
                jobTitle: jobTitle,
                uid: user.user.uid,
                role: enums.MENTOR,
                mentees: [],
                isMentor: false,
                registeredOn: Date.now()
            });
            navigate('/login')
        } catch (err) {
            console.log(err.message)
            toast.error(err.message);
        }
    }

    const { bgcolor, counter } = useMemo(() => {
        let counter = 0;
        let bgcolor = grey[300];

        if (password?.length > 0) {
            bgcolor = yellow[500];
            counter += 1;
            if (password?.length > 5) {
                counter += 1;
                if (/(?=.*[a-z])/.test(password) && /(?=.*[A-Z])/.test(password)) {
                    counter += 1;
                }
                if (/(?=.*[-+_!@#$%^&*.,?])/.test(password)) {
                    counter += 1;
                }
                if (/(?=.*[\d])/.test(password)) {
                    counter += 1;
                }
            }
        }

        if (counter === 1) {
            bgcolor = yellow[500]
        } else if (counter === 2) {
            bgcolor = orange[300]
        } else if (counter === 3) {
            bgcolor = orange[600]
        } else if (counter === 4) {
            bgcolor = green[400]
        } else if (counter === 5) {
            bgcolor = green[600]
        }

        return { bgcolor, counter }
    }, [password])

    const partners = process.env.REACT_APP_PARTNERS

    async function authWithLinkedin() {
        console.log('linkedin auth')
        try {
            const result = await linkedinAuth()
            console.log(result)
            // window.location.href = await linkedinAuth()
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <Box id={"signup-page"}
            sx={{ backgroundImage: `url(${background})`, backgroundSize: 'cover', minHeight: '100vh' }}>
            <MiniHeader />
            <Stack direction={"column"} sx={{ width: "100%", height: "100%" }} justifyContent={'center'}
                alignItems={"center"}>
                <Box sx={{
                    bgcolor: "#FFF",
                    border: 1,
                    borderRadius: 5,
                    borderColor: "divider",
                    p: 5,
                    boxShadow: 3,
                    minWidth: "300px",
                    width: '50%',
                    maxWidth: '500px',
                    marginTop: 10,
                    marginBottom: 10
                }}>
                    <Stack direction={"column"} gap={3} sx={{ width: "100%", height: "100%" }} justifyContent={'center'}
                        alignItems={"center"}>
                        {/*HEADER*/}
                        <Stack direction={"column"} gap={1} sx={{ width: '100%' }} alignItems={'center'}>
                            {/*TITLE*/}
                            <Typography sx={{ color: grey[700] }} variant={'h3'} textAlign={"center"}
                                fontWeight={"bold"}>Mentor Sign up </Typography>
                            {/*SIGN UP*/}
                            <Stack direction={'row'} justifyContent={'center'}>
                                <Typography sx={{ color: grey[700] }} component={"span"}>Already have an
                                    account?</Typography>
                                <Typography component={Link} to={"/login"} sx={{
                                    pl: 1,
                                    color: theme.palette.purple.main,
                                    fontWeight: 'bold',
                                    textDecoration: "none"
                                }}>Log in</Typography>
                            </Stack>
                            {/* <Typography sx={{color: grey[700]}} component={"span"}>or</Typography>

                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<LinkedInIcon/>}
                                onClick={() => {
                                    authWithLinkedin()
                                }}
                                size="large"
                                sx={{maxWidth: '150px'}} // Add some margin for styling
                            >
                                Sign in
                            </Button> */}

                        </Stack>
                        {/*LOGIN WITH MAIL*/}
                        <Stack direction={"column"} gap={2} sx={{ width: '100%' }}>
                            <TextField
                                type={"text"}
                                value={company?.charAt(0).toUpperCase() + company.slice(1)}
                                onChange={(e) => setCompany(e.target.value)}
                                variant={"standard"}
                                required
                                fullWidth
                                label={"Company"}
                                disabled={partners.includes(company)}
                                placeholder={"Company name"}
                            />

                            <TextField
                                type={"name"}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                variant={"standard"}
                                required
                                fullWidth
                                label={"Name"}
                                placeholder={"Enter your first and last name"}
                            />
                            <TextField
                                type={"text"}
                                value={jobTitle}
                                onChange={(e) => setJobTitle(e.target.value)}
                                variant={"standard"}
                                required
                                fullWidth
                                label={"Title"}
                                placeholder={"Whats your title"}
                            />
                            <TextField
                                type={"text"}
                                value={jobDescription}
                                onChange={(e) => setJobDescription(e.target.value)}
                                variant={"standard"}
                                required
                                fullWidth
                                multiline
                                rows={3}
                                label={"Job Description"}
                                placeholder={"Product Manager specializing in AI to protect the environment..."}
                            /> 
                            <TextField
                                type={"text"}
                                value={linkedin}
                                onChange={(e) => setLinkedin(e.target.value)}
                                variant={"standard"}
                                required
                                fullWidth
                                label={"LinkedIn URL"}
                                placeholder={"https://www.linkedin.com/in/your-profile"}
                            />
                            <TextField
                                type={"text"}
                                value={location}
                                onChange={(e) => setLocation(e.target.value)}
                                variant={"standard"}
                                required
                                fullWidth
                                label={"Location"}
                                placeholder={"Dubai"}
                            />
                            <TextField
                                type={"email"}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                variant={"standard"}
                                required
                                fullWidth
                                label={"Email"}
                                placeholder={"Enter your email address"}
                            />
                            <TextField
                                type={"password"}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                variant={"standard"}
                                required
                                fullWidth
                                label={"Password"}
                                placeholder={"Password"}
                            />
                            <TextField
                                type={"password"}
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                variant={"standard"}
                                required
                                fullWidth
                                label={"Type your password again"}
                                placeholder={"Password"}
                            />

                            <Button
                                fullWidth
                                variant={"contained"}
                                onClick={signUp}
                                sx={{
                                    textTransform: "capitalize",
                                    fontWeight: "bold",
                                    '&:hover': { bgcolor: lightBlue[600] }
                                }}
                            >
                                Sign up <ArrowForwardIos sx={{ fontSize: 12 }} />
                            </Button>
                            <Box sx={{ width: "100%" }}>
                                <Typography fontSize={13}>Must have at least 6 characters.</Typography>
                                <Stack direction={"row"} sx={{ width: "100%", height: 3, mt: 1 }} gap={1}>
                                    <Divider color={bgcolor} sx={{ flex: 1 }} />
                                    <Divider color={counter >= 2 ? bgcolor : grey[300]} sx={{ flex: 1 }} />
                                    <Divider color={counter >= 3 ? bgcolor : grey[300]} sx={{ flex: 1 }} />
                                    <Divider color={counter >= 4 ? bgcolor : grey[300]} sx={{ flex: 1 }} />
                                </Stack>
                                <Typography sx={{ mt: 2 }} fontSize={13}>It's better to have:</Typography>
                                <List>
                                    <ListItem sx={{ my: 0, py: 0 }}>
                                        <ListItemText
                                            sx={{ fontSize: 13, px: 0, mx: 0, textAlign: "left" }}
                                            secondary={<>
                                                <Circle sx={{ color: orange[500], fontSize: 10, mr: 1 }} />
                                                Upper & lower case letters
                                            </>}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ my: 0, py: 0 }}>

                                        <ListItemText
                                            sx={{ fontSize: 13, px: 0, mx: 0, textAlign: "left" }}
                                            secondary={<>
                                                <Circle sx={{ color: orange[500], fontSize: 10, mr: 1 }} />
                                                A symbol (#$&)
                                            </>}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ my: 0, py: 0 }}>

                                        <ListItemText
                                            sx={{ fontSize: 13, px: 0, mx: 0, textAlign: "left" }}
                                            secondary={<>
                                                <Circle sx={{ color: orange[500], fontSize: 10, mr: 1 }} />
                                                A longer password
                                            </>}
                                        />
                                    </ListItem>

                                </List>
                            </Box>
                        </Stack>

                    </Stack>
                </Box>
            </Stack>
        </Box>
    )
}
