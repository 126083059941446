import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Button, Card, CardActions, CardContent, CardMedia, IconButton, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useNavigate } from 'react-router-dom';
import { getImageUrl, truncateWords } from '../utils/functions';
import cardBackground1 from '../assets/c-background1.jpg';
import cardBackground2 from '../assets/c-background2.jpg';
import cardBackground3 from '../assets/c-background3.jpg';

const InternshipCard = ({intern, index, companyImage }) => {
    const navigate = useNavigate();
    const backgrounds = [cardBackground1, cardBackground2, cardBackground3];
    const backgroundImage = backgrounds[index % 3];
    return (
        <Card key={intern.uid} sx={{
            maxWidth: 345, 
            marginBottom: 2,
            position: 'relative',
            overflow: 'hidden',
            height: '300px',
            minHeight: '300px',
            borderRadius: '10px'
        }}>
            <Box sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                filter: 'blur(5px)',
                transform: 'scale(1.1)',
                '&::after': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.4)',
                }
            }} />
            <Box sx={{ 
                position: 'relative', 
                zIndex: 1,
                color: 'white'
            }}>
                {<CardMedia
                    component="img"
                    height="100"
                    style={{objectFit: 'contain', padding: 1, visibility: companyImage ? 'visible' : 'hidden'}}
                    image={companyImage}
                    alt={intern.name}
                />}
                <CardContent sx={{minHeight: '100px'}}>
                    <Typography variant={'h5'}><strong>{intern?.name?.replace(/\b\w/g, char => char.toUpperCase())}</strong></Typography>
                    <Typography sx={{mb: 1}} variant={'body1'}>{intern?.jobTitle?.replace(/\b\w/g, char => char.toUpperCase())}</Typography>
                    <Typography variant={'body1'}>
                        {truncateWords(intern?.jobDescription, 10)}
                    </Typography>
                </CardContent>
                <CardActions sx={{p: 2}}>
                    <Button size="small" variant={'contained'}
                            onClick={() => navigate(`/internship/${intern.internshipUid}`)}>
                        view
                    </Button>
                </CardActions>
            </Box>
        </Card>
    );
};

export default InternshipCard; 