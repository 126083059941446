import React, {useMemo, useState} from "react";
import {
    Box,
    Button,
    Checkbox,
    Divider,
    Fade,
    FormControlLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem,
    Stack,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import {Link} from "react-router-dom";
import {green, grey, lightBlue, orange, teal, yellow} from "@mui/material/colors";
import GoogleButton from "react-google-button";
import {ArrowForwardIos, Circle} from "@mui/icons-material";
import {MiniHeader} from "../../components/header/MiniHeader";
import {auth} from "../../config/firebase";
import {createUserWithEmailAndPassword} from "firebase/auth"
import {toast} from "react-toastify";
import {db} from "../../config/firebase";
import {doc, setDoc} from "firebase/firestore";
import {enums} from "../../utils/enums";
import background from "../../assets/milky-way.png";
import { useTheme } from "@mui/material/styles";

export const RegisterPage = () => {
    const theme = useTheme();
    const [email, setEmail] = useState("");
    const [confirmEmail, setConfirmEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const signUp = async () => {
        if (!email || !password || !confirmPassword) {
            toast.error("All fields are required!");
            return;
        }
       
        if (password !== confirmPassword) {
            toast.error("Password do not match");
            return;
        }
        try {
            const user = await createUserWithEmailAndPassword(auth, email, password);
            await setDoc(doc(db, 'users', user.user.uid), {
                email: email,
                uid: user.user.uid,
                role: enums.USER,
                registeredOn: new Date().now()
            });
        } catch (err) {
            toast.error(err.message);
        }
    }

    const {bgcolor, counter} = useMemo(() => {
        let counter = 0;
        let bgcolor = grey[300];

        if (password?.length > 0) {
            bgcolor = yellow[500];
            counter += 1;
            if (password?.length > 5) {
                counter += 1;
                if (/(?=.*[a-z])/.test(password) && /(?=.*[A-Z])/.test(password)) {
                    counter += 1;
                }
                if (/(?=.*[-+_!@#$%^&*.,?])/.test(password)) {
                    counter += 1;
                }
                if (/(?=.*[\d])/.test(password)) {
                    counter += 1;
                }
            }
        }

        if (counter === 1) {
            bgcolor = yellow[500]
        } else if (counter === 2) {
            bgcolor = orange[300]
        } else if (counter === 3) {
            bgcolor = orange[600]
        } else if (counter === 4) {
            bgcolor = green[400]
        } else if (counter === 5) {
            bgcolor = green[600]
        }

        return {bgcolor, counter}
    }, [password])

    return (
        <Box id={"signup-page"}
        sx={{backgroundImage: `url(${background})`, backgroundSize: 'cover', minHeight: '100vh'}}>
        
            <MiniHeader/>

            <Stack direction={"column"} sx={{width: "100%", height: "100%"}} justifyContent={'center'}
                   alignItems={"center"}>
                <Box sx={{
                    bgcolor: "#FFF",
                    border: 1,
                    borderRadius: 5,
                    borderColor: "divider",
                    p: 5,
                    boxShadow: 3,
                    minWidth: "300px",
                    height: 'auto',
                    width: '30%',
                    marginTop: 10,
                    marginBottom: 10
                }}>
                    <Stack direction={"column"} gap={3} sx={{width: "100%", height: "100%"}} justifyContent={'center'}
                           alignItems={"center"}>
                        {/*HEADER*/}
                        <Stack direction={"column"} gap={2} sx={{width: '100%'}}>
                            {/*TITLE*/}
                            <Typography sx={{color: theme.palette.purple.main}} variant={'h4'} textAlign={"center"}
                                        fontWeight={"bold"}>Sign up</Typography>
                            {/*SIGN UP*/}
                            <Box>
                                <Typography sx={{color: grey[700]}} component={"span"}>Already have an
                                    account?</Typography>
                                    <Typography component={Link} to={"/login"} sx={{
                                    pl: 1,
                                    color: theme.palette.purple.main,
                                    fontWeight: 'bold',
                                    textDecoration: "underline"
                                }}>Log in</Typography>
                            </Box>
                        </Stack>
                        {/*LOGIN WITH MAIL*/}
                        <Stack direction={"column"} gap={2} sx={{width: '100%'}}>
                            <TextField
                                type={"email"}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                variant={"standard"}
                                required
                                fullWidth
                                label={"Email"}
                                placeholder={"Enter your email address"}
                            />
                           
                            <TextField
                                type={"password"}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                variant={"standard"}
                                required
                                fullWidth
                                label={"Password"}
                                placeholder={"Password"}
                            />
                            <TextField
                                type={"password"}
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                variant={"standard"}
                                required
                                fullWidth
                                label={"Type your password again"}
                                placeholder={"Password"}
                            />

                            <Button
                                fullWidth
                                variant={"contained"}
                                onClick={signUp}
                                sx={{
                                    textTransform: "capitalize",
                                    bgcolor: theme.palette.purple.main,
                                    fontWeight: "bold",
                                    '&:hover': {bgcolor: theme.palette.purple.dark}
                                }}
                            >
                                Sign up <ArrowForwardIos sx={{fontSize: 12}}/>
                            </Button>
                            <Box sx={{width: "100%"}}>
                                <Typography fontSize={13}>Must have at least 6 characters.</Typography>
                                <Stack direction={"row"} sx={{width: "100%", height: 3, mt: 1}} gap={1}>
                                    <Divider color={bgcolor} sx={{flex: 1}}/>
                                    <Divider color={counter >= 2 ? bgcolor : grey[300]} sx={{flex: 1}}/>
                                    <Divider color={counter >= 3 ? bgcolor : grey[300]} sx={{flex: 1}}/>
                                    <Divider color={counter >= 4 ? bgcolor : grey[300]} sx={{flex: 1}}/>
                                </Stack>
                                <Typography sx={{mt: 2}} fontSize={13}>It's better to have:</Typography>
                                <List>
                                    <ListItem sx={{my: 0, py: 0}}>
                                        <ListItemText
                                            sx={{fontSize: 13, px: 0, mx: 0, textAlign: "left"}}
                                            secondary={<>
                                                <Circle sx={{color: orange[500], fontSize: 10, mr: 1}}/>
                                                Upper & lower case letters
                                            </>}
                                        />
                                    </ListItem>
                                    <ListItem sx={{my: 0, py: 0}}>

                                        <ListItemText
                                            sx={{fontSize: 13, px: 0, mx: 0, textAlign: "left"}}
                                            secondary={<>
                                                <Circle sx={{color: orange[500], fontSize: 10, mr: 1}}/>
                                                A symbol (#$&)
                                            </>}
                                        />
                                    </ListItem>
                                    <ListItem sx={{my: 0, py: 0}}>

                                        <ListItemText
                                            sx={{fontSize: 13, px: 0, mx: 0, textAlign: "left"}}
                                            secondary={<>
                                                <Circle sx={{color: orange[500], fontSize: 10, mr: 1}}/>
                                                A longer password
                                            </>}
                                        />
                                    </ListItem>

                                </List>
                            </Box>
                        </Stack>

                    </Stack>
                </Box>
            </Stack>
        </Box>
    )
}