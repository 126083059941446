import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import QuestionMark from '@mui/icons-material/QuestionMark';
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { useParams } from "react-router-dom";

const AssessmentToolkitKeyStage2 = () => {
    const { className } = useParams();

    return (
        <>
            <Box sx={{ mt: 4 }}>
                {/* Rubric Section */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h4" gutterBottom>
                        1. Rubric for AI-Enhanced Storytelling
                    </Typography>
                    
                    {/* Creative Use of AI */}
                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h5" color="primary" gutterBottom>
                            Creative Use of AI
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Evaluates how well students use AI suggestions to brainstorm and develop story elements, showing an ability to guide AI effectively while making the story their own.
                        </Typography>
                        <Typography variant="body2" sx={{ fontStyle: 'italic', color: 'text.secondary' }}>
                            Rating Scale: 1 = Emerging, 2 = Developing, 3 = Proficient, 4 = Advanced
                        </Typography>
                    </Box>

                    {/* Personalization */}
                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h5" color="primary" gutterBottom>
                            Personalization of AI-Generated Content
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Assesses the student's skill in modifying or building on AI-generated suggestions, demonstrating creativity and ownership over the final story.
                        </Typography>
                        <Typography variant="body2" sx={{ fontStyle: 'italic', color: 'text.secondary' }}>
                            Rating Scale: 1 = Emerging, 2 = Developing, 3 = Proficient, 4 = Advanced
                        </Typography>
                    </Box>

                    {/* Story Structure */}
                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h5" color="primary" gutterBottom>
                            Story Structure with AI Support
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Measures the coherence of the story's structure (beginning, middle, end), particularly noting how the student uses AI prompts to create smooth transitions or resolve the story.
                        </Typography>
                        <Typography variant="body2" sx={{ fontStyle: 'italic', color: 'text.secondary' }}>
                            Rating Scale: 1 = Emerging, 2 = Developing, 3 = Proficient, 4 = Advanced
                        </Typography>
                    </Box>

                    {/* Character Development */}
                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h5" color="primary" gutterBottom>
                            Character Development Using AI
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Evaluates the depth and consistency of characters created, emphasizing how students used AI to generate or refine character traits and motivations.
                        </Typography>
                        <Typography variant="body2" sx={{ fontStyle: 'italic', color: 'text.secondary' }}>
                            Rating Scale: 1 = Emerging, 2 = Developing, 3 = Proficient, 4 = Advanced
                        </Typography>
                    </Box>
                </Box>

                {/* Student Checklist Section */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h4" gutterBottom>
                        2. AI Reflection Checklist for Students
                    </Typography>
                    <List>
                        {[
                            "Did I use AI to brainstorm ideas for my story?",
                            "How did I change the AI's suggestions to make the story unique?",
                            "Did the AI help me think of new plot twists or character details?",
                            "How did I decide what to keep or change from the AI suggestions?"
                        ].map((question, index) => (
                            <ListItem key={index} sx={{ pl: 2 }}>
                                <ListItemIcon>
                                    <CheckCircleOutline color="primary" />
                                </ListItemIcon>
                                <ListItemText primary={question} />
                            </ListItem>
                        ))}
                    </List>
                </Box>

                {/* Peer Feedback Section */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h4" gutterBottom>
                        3. Peer Feedback with AI Focus
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemText 
                                primary="Creative AI Use"
                                secondary="What did you find interesting about how they used AI in the story?"
                                primaryTypographyProps={{ color: 'primary', fontWeight: 'medium' }}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText 
                                primary="Suggestions for Personalization"
                                secondary="What's one idea to make the story feel more like their own?"
                                primaryTypographyProps={{ color: 'primary', fontWeight: 'medium' }}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText 
                                primary="Favorite AI-Generated Element"
                                secondary="What was your favorite character or scene, and why?"
                                primaryTypographyProps={{ color: 'primary', fontWeight: 'medium' }}
                            />
                        </ListItem>
                    </List>
                </Box>

                {/* Teacher Feedback Section */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h4" gutterBottom>
                        4. Teacher Feedback Guide
                    </Typography>
                    <Box sx={{ pl: 2 }}>
                        <Typography variant="h6" color="primary" gutterBottom>
                            Encouragement and Constructive Feedback
                        </Typography>
                        <Typography variant="body1" paragraph sx={{ pl: 2 }}>
                            Provide feedback on the student's engagement with AI, noting both effective uses and opportunities for further exploration.
                        </Typography>

                        <Typography variant="h6" color="primary" gutterBottom>
                            Balanced AI Use
                        </Typography>
                        <Typography variant="body1" paragraph sx={{ pl: 2 }}>
                            Comment on the balance between AI-generated and student-created content, encouraging deeper personalization of AI prompts.
                        </Typography>
                    </Box>
                </Box>

                {/* Reflective Questions Section */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h4" gutterBottom>
                        5. Reflective Questions on AI's Role
                    </Typography>
                    <List>
                        {[
                            "How did using AI change the way you approached storytelling?",
                            "What were some of the best ideas the AI helped you come up with?",
                            "What would you do differently next time when using AI for writing?"
                        ].map((question, index) => (
                            <ListItem key={index}>
                                <ListItemIcon>
                                    <QuestionMark color="primary" />
                                </ListItemIcon>
                                <ListItemText primary={question} />
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Box>
        </>
    );
};

export default AssessmentToolkitKeyStage2; 