import { Avatar, IconButton, Stack, Tab, Tabs, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import {
    IconArrowLeft,
    IconBook,
    IconFolder,
    IconBookmarks
} from '@tabler/icons-react';
import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MainLayout } from "../../layouts/main-layout/MainLayout";
import { Context as AppContext } from '../../context/AppContext';



const ClassPage = () => {
    const { className } = useParams();
    const navigate = useNavigate();
    const [keyStage, setKeyStage] = useState('ks2');
    const [filteredModules, setFilteredModules] = useState([]);
    const { state: { modules, componentMapping }} = useContext(AppContext);
    const handleKeyStageChange = (event, newValue) => {
        setKeyStage(newValue);
    };

    function filterModules(keyStage) {
        return modules?.filter(module => module.keyStage === keyStage);
    }   
    useEffect(() => {
        const filteredModules = filterModules(keyStage);
        console.log(filteredModules);
        setFilteredModules(filteredModules);
    }, [keyStage, modules]);

    const navigateToPage = (module) => {
        console.log(module);
        navigate(`/resource-hub/${className}/${module.module}`);
    }

    return (
        <MainLayout>
            <Box sx={{
                height: '80vh',
                p: 3,
                position: 'relative',
                display: 'flex',
                flexDirection: "column"
            }}>
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    alignItems={{ xs: 'flex-start', sm: 'center' }}
                    spacing={2}
                >
                    <IconButton
                        onClick={() => navigate(-1)}
                        sx={{
                            color: 'inherit',
                            '&:hover': {
                                background: 'rgba(0, 0, 0, 0.04)'
                            }
                        }}
                    >
                        <IconArrowLeft />
                    </IconButton>
                    <Typography variant={'h3'}>
                        {className}
                    </Typography>
                </Stack>



                <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 3 }}>
                    <Tabs
                        value={keyStage}
                        onChange={handleKeyStageChange}
                        sx={{
                            '& .Mui-selected': {
                                background: 'linear-gradient(90deg, #4c81f2, #a670bd, #a670bd)',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                            },
                            '& .MuiTabs-indicator': {
                                background: 'linear-gradient(90deg, #4c81f2, #a670bd, #a670bd)',
                            }
                        }}
                    >
                        <Tab label="Key Stage 1" value="ks1" sx={{ fontFamily: 'Poppins' }} />
                        <Tab label="Key Stage 2" value="ks2" sx={{ fontFamily: 'Poppins' }} />
                        <Tab label="Key Stage 3" value="ks3" sx={{ fontFamily: 'Poppins' }} />
                    </Tabs>
                </Box>
                
                {filteredModules?.map((module, index) => (
                    <Box key={index} sx={{ m: 2 }}>
                        <Stack direction={'row'} spacing={2} alignItems={'center'}
                            sx={{
                                '&:hover': {
                                    cursor: 'pointer',
                                    scale: 1.03,
                                    background: 'linear-gradient(90deg, #4c81f2, #a670bd, #a670bd)',
                                    WebkitBackgroundClip: 'text',
                                    WebkitTextFillColor: 'transparent',
                                    fontWeight: 'bold'
                                }
                            }}
                            onClick={() => navigateToPage(module)}
                        >
                            <IconBookmarks />
                            <Typography variant={'h4'}>{module.title}</Typography>
                        </Stack>
                        
                        {/* {module.components.length > 0 ? module.components.map((component, index) => {
                            const Component = componentMapping[component.componentName];
                            return (
                                <Stack key={index} direction={'row'} spacing={2} alignItems={'center'}>
                                    <Typography variant={'h5'}>{component.title}</Typography>
                                    {Component && <Component />}
                                </Stack>
                            );
                        }) : <Typography variant={'h5'}>No resources found</Typography>} */}
                    </Box>
                ))}
            </Box>
        </MainLayout>
    );
};

export default ClassPage;
//
// Teacher-Resource-Hub-29cd1852b43c44d182791ff574780bfd
// https://www.notion.so/Teacher-Resource-Hub-29cd1852b43c44d182791ff574780bfd
// https://notion-api.splitbee.io/v1/page/Teacher-Resource-Hub-29cd1852b43c44d182791ff574780bfd
