import {
    IconButton,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from "@mui/material";
import Box from "@mui/material/Box";
import { IconCheck, IconX } from "@tabler/icons-react";
import React, {useContext} from 'react';
import {Context as AppContext} from "../../context/AppContext";
import './mentorHomepage.css'

const ListOfInterns = ({students, applications=true}) => {
    const {state: {currentUser}, updateUserData, updateInternData, createInternship} = useContext(AppContext);

    if (!students) {
        return <Typography>Thank you for mentoring! You will be notified when you're matched with a
            student...</Typography>
    }
    const handleAccept = (studentId) => {
        currentUser.mentees = currentUser.mentees ? [...currentUser.mentees, studentId] : [studentId]
        const internshipUid = crypto.randomUUID()
        updateUserData({...currentUser})
        updateInternData({
            mentorUid: currentUser.uid, 
            uid: studentId,
            acceptedAt: Date.now(),
            internshipUid: internshipUid
        })
        createInternship({
            mentorUid: currentUser.uid,
            uid: internshipUid,
            studentUid: studentId,
            startDate: new Date().toISOString(),
            endDate: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString()
        })
        console.log(`Accepted student with ID: ${studentId}`);
    };

    const handleReject = (studentId) => {
        // Handle reject logic here
        console.log(`Rejected student with ID: ${studentId}`);
    };


    return (
        <Box component={Paper} sx={{mt: 2, borderRadius: 2, dropShadow: 0   }}>
            <Typography variant={'h4'} sx={{p: 4}}>{applications ? 'Applicants' : 'Mentees'}</Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{fontWeight: 600}}></TableCell>
                        <TableCell sx={{fontWeight: 600}}>Name</TableCell>
                        <TableCell sx={{fontWeight: 600}}>Job Title</TableCell>
                        <TableCell sx={{fontWeight: 600}}>Job Description</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {students?.length === 0 && <TableRow><TableCell colSpan={5}>There are no students that applied for
                        mentorship...</TableCell></TableRow>}
                    {students.map(item => {
                        return (
                            <TableRow key={item?.uid}>
                                <TableCell sx={{width: 0}}>
                                    <Stack direction={"row"} gap={1}>
                                        {currentUser.mentees?.includes(item.uid) ? (
                                            <Typography 
                                                variant="caption" 
                                                className="rainbow-text"
                                                sx={{ 
                                                    color: 'success.main',
                                                    fontWeight: 600,
                                                    alignSelf: 'center'
                                                }}
                                            >
                                                MENTEE
                                            </Typography>
                                        ) : (
                                            <>
                                                <IconButton onClick={() => handleAccept(item.uid)}>
                                                    <Tooltip title={'Accept'}>
                                                        <IconCheck size={20} stroke={1.5} />
                                                    </Tooltip>
                                                </IconButton>
                                                <IconButton onClick={() => handleReject([item?.uid])}>
                                                    <Tooltip title={'Reject'}>
                                                        <IconX size={20} stroke={1.5} />
                                                    </Tooltip>
                                                </IconButton>
                                            </>
                                        )}
                                    </Stack>
                                </TableCell>
                                <TableCell>{item?.name}</TableCell>
                                <TableCell>{item.jobTitle}</TableCell>
                                <TableCell>{item.jobDescription}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </Box>
    );
};


export default ListOfInterns;
