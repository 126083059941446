import React, { useContext, useEffect } from 'react';
import { Box, Button, Stack, Typography } from "@mui/material";
import Lottie from "lottie-react";
import { Context as AppContext } from "../../context/AppContext";
import { useNavigate } from "react-router-dom";
import ListOfInternships from "./ListOfInterships"
import { MainLayout } from "../../layouts/main-layout/MainLayout";
import animationData from '../../assets/mentor.json'; // Adjust the path to your Lottie animation file
import './mentorHomepage.css'
import { toast } from "react-toastify";
import { capitalizeFirstWord } from '../../utils/functions';
const MentorHomepage = () => {
    const { state: { currentUser, interns, internships }, updateUserData, getInterns, getInternships } = useContext(AppContext);
    const navigate = useNavigate()

    useEffect(() => {
        if (currentUser) {
            if (currentUser.mentees && (!interns || interns?.length === 0)) {
                console.log('currentUser.mentees', currentUser.mentees)
                getInterns(currentUser.mentees)
                console.log('interns', interns)
            }
            if (!internships || internships?.length === 0) {
                getInternships({uid: currentUser.uid, type: currentUser.role})
            }
        }
    }, [currentUser])

   console.log(interns);
   console.log(internships);

    function setMentorVisible() {
        currentUser.isMentor = true
        updateUserData(currentUser)
        toast.success('You are now a mentor!')
    }

    return (
        <MainLayout>
            <Stack gap={4} sx={{ 
                minHeight: 'fit-content',
                maxHeight: '100%',
                pt: 3,
                px: 3,
                pb: 0,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                overflow: 'auto',
                '& > *:last-child': {
                    mb: 0
                }
            }}>
                <Typography variant={'h3'} alignSelf="flex-start">
                    Welcome,
                    <span className={'rainbow-text'}> {capitalizeFirstWord(currentUser?.name)} </span>
                </Typography>

                {currentUser?.mentees?.length > 0 || currentUser?.isMentor ? (
                    <Box sx={{ width: '100%', flex: 1 }}>
                        <ListOfInternships interns={interns} applications={false} />
                    </Box>
                ) : (
                    <>
                        <Typography variant={'h5'}>You are not a mentor yet...</Typography>
                        <Lottie 
                            animationData={animationData} 
                            loop={true} 
                            style={{ height: '300px', width: '300px' }} 
                        />
                        <Button 
                            sx={{ maxWidth: '300px', width: '250px' }} 
                            variant={'outlined'} 
                            onClick={() => setMentorVisible()}
                        >
                            <span className="rainbow-text">Start mentoring</span>
                        </Button>
                    </>
                )}
            </Stack>
        </MainLayout>
    );
};

export default MentorHomepage;
