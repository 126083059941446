import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import Lottie from "lottie-react";
import React, { useContext, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import animationData from '../../assets/aiden boat.json';
import { Context as AppContext } from "../../context/AppContext";
import { MainLayout } from "../../layouts/main-layout/MainLayout";
import ListOfInterns from "./ListOfInterns";
import './mentorHomepage.css';
const Applicants = () => {
    const { state: { currentUser, applicants }, getApplicants } = useContext(AppContext);

    useEffect(() => {
        if (currentUser) {
            getApplicants(currentUser.uid)
        }
    }, [currentUser])

    return (
        <MainLayout>
            <Box sx={{ p: 3 }}>
                <Stack gap={1}>
                    {applicants === null ? (
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                            <CircularProgress />
                            <Typography>Loading applicants...</Typography>
                        </Box>
                    ) : applicants?.length > 0 ? (
                        <ListOfInterns students={applicants} />
                    ) : (
                        <>
                            <Typography variant={'h3'}>Applicants</Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                                <Lottie animationData={animationData} loop={true} style={{ height: '400px', width: '400px' }} />
                                <Typography variant={'h5'}>No students have applied yet...</Typography>
                            </Box>
                        </>
                    )}
                </Stack>
            </Box>
        </MainLayout>
    );
};

export default Applicants;
