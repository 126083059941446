import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import QuestionMark from '@mui/icons-material/QuestionMark';
import { Box, List, ListItem, ListItemIcon, ListItemText, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useParams } from "react-router-dom";

const AssessmentToolkitKeyStage3 = () => {

    return (
<>
            <Box sx={{ mt: 4 }}>
                {/* Purpose Section */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h4" gutterBottom>
                        Assessment Toolkit
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Purpose: Provide teachers with tools to assess students’ analytical skills, use of AI, and critical thinking during the lesson.
                    </Typography>
                </Box>

                {/* Assessment Components Section */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h4" gutterBottom>
                        Assessment Components
                    </Typography>

                    {/* Formative Assessment Rubric */}
                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h5" color="primary" gutterBottom>
                            Formative Assessment Rubric
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Criteria:
                        </Typography>
                        <List>
                            {["Engagement with AI: Did the student use AI effectively to generate insights?",
                              "Analysis Quality: Were the student’s insights (both AI-driven and independent) well-supported by evidence from the text?",
                              "Critical Thinking: Did the student critically evaluate AI’s suggestions and incorporate their own ideas?",
                              "Collaboration: How well did the student participate in group discussions or Think-Pair-Share?"].map((criteria, index) => (
                                <ListItem key={index} sx={{ pl: 2 }}>
                                    <ListItemIcon>
                                        <CheckCircleOutline color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary={criteria} />
                                </ListItem>
                            ))}
                        </List>
                        <Typography variant="body2" sx={{ fontStyle: 'italic', color: 'text.secondary' }}>
                            Scoring: A scale from 1-5 for each criterion, with space for teacher comments.
                        </Typography>
                    </Box>

                    {/* Self-Assessment Checklist */}
                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h5" color="primary" gutterBottom>
                            Self-Assessment Checklist
                        </Typography>
                        <List>
                            {["I used AI effectively to generate insights.",
                              "I provided evidence for my arguments during discussion.",
                              "I compared AI’s analysis with my own and identified differences."].map((prompt, index) => (
                                <ListItem key={index} sx={{ pl: 2 }}>
                                    <ListItemIcon>
                                        <CheckCircleOutline color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary={prompt} />
                                </ListItem>
                            ))}
                        </List>
                    </Box>

                    {/* Peer Review Sheet */}
                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h5" color="primary" gutterBottom>
                            Peer Review Sheet
                        </Typography>
                        <List>
                            {["Strength of arguments.",
                              "How effectively AI insights were integrated.",
                              "Suggestions for improvement."].map((criteria, index) => (
                                <ListItem key={index} sx={{ pl: 2 }}>
                                    <ListItemIcon>
                                        <QuestionMark color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary={criteria} />
                                </ListItem>
                            ))}
                        </List>
                    </Box>

                    {/* Example Rubric Excerpt */}
                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h5" color="primary" gutterBottom>
                            Example Rubric Excerpt
                        </Typography>
                        <TableContainer component={Paper}>
                            <Table aria-label="example rubric table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Criteria</TableCell>
                                        <TableCell align="center">1 - Needs Improvement</TableCell>
                                        <TableCell align="center">3 - Satisfactory</TableCell>
                                        <TableCell align="center">5 - Excellent</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Engagement with AI</TableCell>
                                        <TableCell align="center">Rarely uses AI prompts.</TableCell>
                                        <TableCell align="center">Uses AI prompts but only basic insights.</TableCell>
                                        <TableCell align="center">Fully utilizes AI to explore multiple layers of analysis.</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Analysis Quality</TableCell>
                                        <TableCell align="center">Insights lack evidence.</TableCell>
                                        <TableCell align="center">Some insights supported.</TableCell>
                                        <TableCell align="center">Well-supported, in-depth analysis.</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default AssessmentToolkitKeyStage3; 