import React, {useContext, useEffect, useState} from 'react';
import {Box, IconButton, Stack, TextField, Typography} from "@mui/material";
import ChatWindow from "./ChatWindow";
import loadingGif from "../../assets/ai-loading.webm";
import SendIcon from "@mui/icons-material/Send";
import {Context as AppContext} from "../../context/AppContext";
import {toast} from "react-toastify";
import {httpsCallable} from "firebase/functions";
import {functions} from "../../config/firebase";
import aidenSmall from "../../assets/small-logo.png";
import { keyframes } from '@mui/system';
import PropTypes from 'prop-types';

// TODO: Consider moving animations to a shared styles file
const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const AiChat = ({
    title,                    // Page title
    initialMessages = [],     // Initial messages to populate the chat
    showSelectors = false,    // Whether to show class/grade selectors
    SelectionComponent,       // Custom selection component (optional)
    customPlaceholder,        // Custom placeholder for text input
    firebaseFunctionName = "getChatCompletionC",  // Name of Firebase function to call
}) => {
    const {state: {messages, currentUser}, updateMessages} = useContext(AppContext)
    const getChatCompletion = httpsCallable(functions, firebaseFunctionName);
    const [isAnswering, setIsAnswering] = useState(false);
    const [userQuery, setUserQuery] = useState('');
    const [chatGptUserQuery, setChatGptUserQuery] = useState(null);
    const [getResponseFromChat, setGetResponseFromChat] = useState(0);

    // TODO: Consider if these should be moved to SelectionComponent
    const [selectedOptions, setSelectedOptions] = useState({});

    useEffect(() => {
        // Initialize with provided messages
        updateMessages(initialMessages);
    }, []);

    useEffect(() => {
        if (getResponseFromChat > 0) {
            sendChatGptPrompt();
        }
    }, [getResponseFromChat]);

    useEffect(() => {
        if (!chatGptUserQuery) return;

        messages.push(chatGptUserQuery);
        updateMessages([...messages])
        setUserQuery('');
        getMessages();

    }, [chatGptUserQuery]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.ctrlKey && event.key === 'Enter') {
                setGetResponseFromChat(prev => prev + 1);
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, []);

    // TODO: Consider making error messages configurable
    async function getMessages() {
        try {
            setIsAnswering(true);
            const response = await getChatCompletion({messages: messages});

            if (response.data) {
                messages.push(response.data.message);
                updateMessages([...messages])
                setChatGptUserQuery(null);
            } else {
                toast.error('Something went wrong, please try again!');
            }
        } catch (error) {
            console.error("Error fetching chat completion:", error);
            toast.error('An error occurred, please try again!');
        } finally {
            setIsAnswering(false);
        }
    }

    function sendChatGptPrompt() {
        setChatGptUserQuery({
            "role": "user",
            "content": userQuery
        })
    }

    // TODO: Consider making styles configurable via props
    return (
            <Box sx={{
                height: 'calc(100% - 60px)',
                 overflow: 'hidden',
                 p: 3,
                 position: 'relative',
                 display: 'flex',
                 flexDirection: "column",
                
            }}>
                <Stack 
                    direction={{ xs: 'column', sm: 'row' }} 
                    alignItems={{ xs: 'flex-start', sm: 'center' }}
                    justifyContent={{ xs: 'flex-start', sm: 'space-between' }}
                    spacing={2}
                >
                    <Typography variant={'h3'}>
                        {title}
                    </Typography>
                    {showSelectors && SelectionComponent && (
                        <SelectionComponent 
                            selectedOptions={selectedOptions}
                            setSelectedOptions={setSelectedOptions}
                        />
                    )}
                </Stack>

                <ChatWindow />
                
                <Stack>
                    {isAnswering && (
                        <img 
                            src={aidenSmall} 
                            alt="AI Assistant" 
                            style={{
                                width: '20px',
                                animation: `${spin} 2s linear infinite`
                            }}
                        />
                    )}
                    <Stack 
                        direction={'row'} 
                        gap={2} 
                        alignItems={'center'} 
                        justifyContent={'center'}
                        sx={{position: 'relative', bottom: 10, width: '90%', paddingLeft: "50px"}}
                    >
                        <TextField
                            placeholder={customPlaceholder || "Start typing..."}
                            multiline 
                            rows={3} 
                            sx={{flex: 1, backgroundColor: 'white'}}
                            value={userQuery} 
                            onChange={e => setUserQuery(e.target.value)}
                        />
                        <Box sx={{textAlign: "right"}}>
                            {isAnswering ? (
                                <video style={{width: '50px'}} autoPlay loop src={loadingGif}/>
                            ) : (
                                <IconButton 
                                    color="primary" 
                                    variant={"outlined"} 
                                    sx={{width: "50px", height: "50px"}}
                                    aria-label="send"
                                    onClick={() => setGetResponseFromChat(prev => prev + 1)}
                                >
                                    <SendIcon/>
                                </IconButton>
                            )}
                        </Box>
                    </Stack>
                </Stack>
            </Box>
    );
};

AiChat.propTypes = {
    // Required props
    title: PropTypes.string.isRequired,
    
    // Optional props with defaults
    initialMessages: PropTypes.arrayOf(PropTypes.shape({
        role: PropTypes.oneOf(['user', 'assistant', 'system']).isRequired,
        content: PropTypes.string.isRequired
    })),
    showSelectors: PropTypes.bool,
    SelectionComponent: PropTypes.elementType,
    customPlaceholder: PropTypes.string,
    firebaseFunctionName: PropTypes.string
};

export default AiChat; 