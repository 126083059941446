import { useState, useEffect } from "react";
import { Box, Button, Popover, Stack, Typography, IconButton, Drawer, Tooltip, Zoom } from "@mui/material";
import { IconArrowLeft, IconBulb } from "@tabler/icons-react";
import { useNavigate, useLocation } from "react-router-dom";
import { MainLayout } from "../layouts/main-layout/MainLayout";
import AiDesignerModal from "../pages/ai-designer/AiDesignerModal"
import aidenLogo from '../assets/small-logo.png';
import PageHeader from "./PageHeader";
const PageWrapper = ({ title, children }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedText, setSelectedText] = useState("");
    const [anchorPosition, setAnchorPosition] = useState(null);
    const [isDesignerOpen, setIsDesignerOpen] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(true);

    const handleTextSelection = () => {
        const selection = window.getSelection();
        const text = selection.toString().trim();
        
        if (text) {
            const range = selection.getRangeAt(0);
            const rect = range.getBoundingClientRect();
            
            setSelectedText(text);
            setAnchorPosition({
                top: rect.bottom + window.scrollY,
                left: rect.left + (rect.width / 2)
            });
        } else {
            setAnchorPosition(null);
        }
    };

    const handleIdeateClick = () => {
        setIsDesignerOpen(true);
        setAnchorPosition(null);
    };

    const handleDesignerClose = () => {
        setIsDesignerOpen(false);
        setSelectedText("");
    };

    const handleBackClick = () => {
        const pathSegments = location.pathname.split('/').filter(Boolean);
        console.log('Navigating to:', pathSegments.slice(0, -1).join('/'));
        
        navigate(pathSegments.slice(0, -1).join('/'));
    };

    useEffect(() => {
        document.addEventListener('mouseup', handleTextSelection);
        return () => {
            document.removeEventListener('mouseup', handleTextSelection);
        };
    }, []);

    // Add this useEffect to track location changes
    useEffect(() => {
        console.log('Location changed to:', location.pathname);
    }, [location]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setTooltipOpen(false);
        }, 5000); // 5 seconds

        return () => clearTimeout(timer); // Cleanup the timer on component unmount
    }, []);

    return (
        <MainLayout>
            <Box sx={{
                height: '80vh',
                p: 3,
                position: 'relative',
                display: 'flex',
                flexDirection: "column"
            }}>
                <PageHeader title={title} />

                <Box sx={{ position: 'relative' }} onMouseUp={handleTextSelection}>
                    {children}
                    <Tooltip 
                        title="Highlight any text to customize the lesson"
                        placement="left"
                        TransitionComponent={Zoom}
                        arrow
                        open={tooltipOpen}
                    >
                        <IconButton
                            sx={{
                                position: 'fixed',
                                bottom: 20,
                                right: 20,
                                width: 60,
                                height: 60,
                                '&:hover': {
                                    backgroundColor: 'transparent'
                                }
                            }}
                        >
                            <img 
                                src={aidenLogo} 
                                alt="Aiden Assistant" 
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain'
                                }}
                            />
                        </IconButton>
                    </Tooltip>
                </Box>

                <Drawer
                    anchor="right"
                    open={isDesignerOpen}
                    onClose={handleDesignerClose}
                    sx={{
                        '& .MuiDrawer-paper': {
                            width: { xs: '100%', sm: '500px' },
                            boxSizing: 'border-box',
                        },
                    }}
                >
                    <AiDesignerModal 
                        selectedText={selectedText}
                        onClose={handleDesignerClose}
                    />
                </Drawer>

                <Popover
                    open={Boolean(anchorPosition)}
                    anchorReference="anchorPosition"
                    anchorPosition={anchorPosition}
                    onClose={() => setAnchorPosition(null)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'end',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'end',
                    }}
                >
                    <Button 
                        onClick={handleIdeateClick}
                        startIcon={<IconBulb size={20} />}
                        sx={{ 
                            p: 1, 
                            m: 0,
                            background: 'linear-gradient(90deg, #4c81f2, #a670bd, #a670bd)',
                            color: 'white',
                            '&:hover': {
                                background: 'linear-gradient(90deg, #4371d8, #955aad, #955aad)',  // Slightly darker gradient for hover
                            }
                        }}
                    >
                        Customize
                    </Button>
                </Popover>
            </Box>
        </MainLayout>
    );
};

export default PageWrapper; 