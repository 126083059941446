import { Stack, IconButton, Typography } from "@mui/material";
import { IconArrowLeft } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import './PageHeader.css';  // We'll create this file for the CSS

const PageHeader = ({ title, rainbowText }) => {
    const navigate = useNavigate();

    const renderTitle = () => {
        if (rainbowText) {
            const [prefix, ...rest] = title.split(rainbowText);
            return (
                <>
                    {prefix}
                    <span className="rainbow-text">{rainbowText}</span>
                    {rest.join(rainbowText)}
                </>
            );
        }
        return title;
    };

    return (
        <Stack
            direction={{ xs: 'column', sm: 'row' }}
            alignItems={{ xs: 'flex-start', sm: 'center' }}
            spacing={2}
        >
            <IconButton 
                onClick={() => navigate(-1)} 
                sx={{ 
                    color: 'inherit',
                    '&:hover': { 
                        background: 'rgba(0, 0, 0, 0.04)' 
                    } 
                }}
            >
                <IconArrowLeft />
            </IconButton>
            <Typography variant={'h3'}>
                {renderTitle()}
            </Typography>
        </Stack>
    );
};

export default PageHeader; 