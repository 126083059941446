const OpenAIApi = require("openai");

export const similarityCheck = async (primaryIdea, ideas) => {
  const similarityBetweenIdeas = [];
  try {
    // First idea is always the one that we run similarity check
    ideas = [primaryIdea, ...ideas?.filter(idea => idea?.uid !== primaryIdea?.uid)];

    const texts = ideas?.map(idea => idea.description);
    const configuration = {
      apiKey: process.env.REACT_APP_OPENAI_API_KEY,
      dangerouslyAllowBrowser: true
    }
    const openai = new OpenAIApi(configuration);

    const response = await openai.embeddings.create({
      input: texts,
      model: "text-embedding-ada-002"
    });
    const embeddings = response?.data?.map((data, i) => {
      return {uid: ideas[i]?.uid, text: ideas[i].description, embedding: data.embedding}
    })
    // Calculate text similarity percentages
    const i = 0;

    for (let j = 1; j < embeddings.length; j++) {
      const similarity = cosineSimilarity(embeddings[i].embedding, embeddings[j].embedding) * 100;
      similarityBetweenIdeas.push({uid: ideas[j].uid, similarity: similarity});
    }
    ideas = ideas?.map(idea => {
      if (idea?.uid === primaryIdea?.uid) {
        return {...idea, similarityToOthers: similarityBetweenIdeas};
      }
      const similarIdea = similarityBetweenIdeas.find(i => i.uid === idea?.uid);
      if (similarIdea) {
        return {...idea, similarityToOthers: [...idea.similarityToOthers, similarIdea]}
      }
      console.log('idea')
      console.log(idea)
      return idea;
    })

  } catch (error) {
    console.error(error);
  }
  return ideas;
}

function cosineSimilarity(embeddingA, embeddingB) {
  const dotProduct = embeddingA.reduce((sum, value, index) => sum + (value * embeddingB[index]), 0);
  const magnitudeA = Math.sqrt(embeddingA.reduce((sum, value) => sum + (value * value), 0));
  const magnitudeB = Math.sqrt(embeddingB.reduce((sum, value) => sum + (value * value), 0));
  return dotProduct / (magnitudeA * magnitudeB);
}