import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Context as AppContext } from "../context/AppContext";
import { enums } from "./enums";

export const Router = () => {
    const { state: { currentUser } } = useContext(AppContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (!currentUser) {
            navigate("/login");
            return;
        }

        // Route based on user role
        switch (currentUser.role) {
            case enums.MENTOR:
                navigate("/mentor", { replace: true });
                break;
            case enums.STUDENT:
                navigate("/student", { replace: true });
                break;
            case enums.INTERN:
                navigate("/intern", { replace: true });
                break;
            case enums.ADMIN:
            case enums.TEACHER:
                navigate("/resource-hub", { replace: true });
                break;
            default:
                navigate("/login", { replace: true });
                break;
        }
    }, [currentUser, navigate]);

    return null;
};
