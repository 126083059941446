import { Box, Typography, List, ListItem, ListItemIcon, ListItemText, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import { useParams } from "react-router-dom";
import PageWrapper from "../../../../../components/PageWrapper";
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import QuestionMark from '@mui/icons-material/QuestionMark';
import SchoolIcon from '@mui/icons-material/School';
import GroupIcon from '@mui/icons-material/Group';
import QuizIcon from '@mui/icons-material/Quiz';
import AssignmentIcon from '@mui/icons-material/Assignment';

const LessonPlanObjectivesKeyStage3 = () => {
    const { className } = useParams();

    return (
        <>
            <Box sx={{ mt: 4 }}>
                {/* Main Title and Session Objectives */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h4" gutterBottom>
                        Lesson Plan: AI-Assisted Literary Analysis
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Key Stage 3 - Alignment: English National Curriculum
                    </Typography>

                    <Typography variant="h4" color="primary" sx={{ mt: 4, mb: 2 }}>
                        Session Objectives
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <CheckCircleOutline color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{ variant: 'h6' }}
                                primary="Enhance Literary Analysis Skills"
                                secondary="Students will critically analyze literary texts, identifying themes, symbolism, and character motivations."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <SchoolIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{ variant: 'h6' }}
                                primary="Leverage AI for Diverse Perspectives"
                                secondary="Students will learn how to use AI tools to gain alternative interpretations of texts and compare them to their own insights."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <GroupIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{ variant: 'h6' }}
                                primary="Collaborate and Reflect"
                                secondary="Through group discussions and reflections, students will evaluate AI-generated insights, determining their validity and relevance."
                            />
                        </ListItem>
                    </List>
                </Box>

                {/* Part 1: Learning Lab */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h4" color="primary" gutterBottom>
                        Part 1: Learning Lab
                    </Typography>

                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h5" color="primary" gutterBottom>
                            Introduction to Literary Analysis and AI Tools (10 min)
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Activity: Class discussion on the importance of literary analysis—how it helps readers uncover deeper meanings in texts. The teacher introduces AI as a tool to provide alternative interpretations and highlight literary elements.
                        </Typography>
                        <Typography variant="h6" color="primary" gutterBottom>
                            Key Concepts
                        </Typography>
                        <List>
                            {[
                                "How themes, character motivations, and symbolism contribute to a text’s meaning.",
                                "The role of AI in identifying patterns and generating insights."
                            ].map((concept, index) => (
                                <ListItem key={index} sx={{ pl: 2 }}>
                                    <ListItemIcon>
                                        <SchoolIcon color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary={concept} />
                                </ListItem>
                            ))}
                        </List>
                    </Box>

                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h5" color="primary" gutterBottom>
                            Demonstration: Analyzing a Text with AI (10 min)
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Example Text: Use an accessible excerpt (e.g., a passage from Of Mice and Men or Macbeth).
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Teacher-Led Demonstration: Input the excerpt into ChatGPT with a prompt such as, “What are the main themes and character motivations in this text?”
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Result Analysis: Discuss AI-generated responses with the class, focusing on:
                        </Typography>
                        <List>
                            {[
                                "How AI identified key themes or motivations.",
                                "Whether students agree with the AI’s analysis and why."
                            ].map((analysis, index) => (
                                <ListItem key={index} sx={{ pl: 2 }}>
                                    <ListItemIcon>
                                        <GroupIcon color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary={analysis} />
                                </ListItem>
                            ))}
                        </List>
                    </Box>

                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h5" color="primary" gutterBottom>
                            Group Activity: AI-Assisted Analysis (15 min)
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Instructions: Divide students into small groups. Each group receives an excerpt from a selected text (e.g., Animal Farm, To Kill a Mockingbird, or Macbeth).
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Task: Students use pre-configured ChatGPT prompts to analyze their excerpt. Prompts may include:
                        </Typography>
                        <List>
                            {[
                                "What are the themes in this excerpt?",
                                "What motivates the characters in this passage?",
                                "What symbols or imagery contribute to the meaning of this text?"
                            ].map((prompt, index) => (
                                <ListItem key={index} sx={{ pl: 2 }}>
                                    <ListItemIcon>
                                        <QuizIcon color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary={prompt} />
                                </ListItem>
                            ))}
                        </List>
                        <Typography variant="body1" paragraph>
                            Discussion: Groups record AI insights and compare them with their own analyses, using a Tracking Worksheet.
                        </Typography>
                    </Box>

                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h5" color="primary" gutterBottom>
                            Comfort Break (5 min)
                        </Typography>
                    </Box>
                </Box>

                {/* Part 2: Practice Pad */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h4" color="primary" gutterBottom>
                        Part 2: Practice Pad
                    </Typography>

                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h5" color="primary" gutterBottom>
                            Classroom Debate: AI vs. Human Interpretation (20 min)
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Activity: Groups present their findings to the class, sharing both AI-generated and student-generated insights.
                        </Typography>
                        <Typography variant="h6" color="primary" gutterBottom>
                            Debate Questions
                        </Typography>
                        <List>
                            {[
                                "Which analysis was more insightful—AI or yours? Why?",
                                "Did the AI notice something you missed? Did you notice something the AI missed?"
                            ].map((question, index) => (
                                <ListItem key={index} sx={{ pl: 2 }}>
                                    <ListItemIcon>
                                        <QuizIcon color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary={question} />
                                </ListItem>
                            ))}
                        </List>
                    </Box>

                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h5" color="primary" gutterBottom>
                            Individual Reflection: AI’s Role in Literary Analysis (10 min)
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Worksheet: Students complete a reflection worksheet with prompts like:
                        </Typography>
                        <List>
                            {[
                                "How did AI’s insights help you understand the text better?",
                                "What would you do differently next time when using AI for analysis?",
                                "Did AI’s analysis feel accurate or too generalized? Why?"
                            ].map((prompt, index) => (
                                <ListItem key={index} sx={{ pl: 2 }}>
                                    <ListItemIcon>
                                        <AssignmentIcon color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary={prompt} />
                                </ListItem>
                            ))}
                        </List>
                    </Box>

                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h5" color="primary" gutterBottom>
                            Plenary (5 min)
                        </Typography>
                        <Typography variant="h6" color="primary" gutterBottom>
                            Mentimeter Poll
                        </Typography>
                        <List>
                            {[
                                "How useful was AI in helping you analyze the text? (Scale 1-5)",
                                "What’s one new thing you learned about the text through AI?"
                            ].map((question, index) => (
                                <ListItem key={index} sx={{ pl: 2 }}>
                                    <ListItemIcon>
                                        <CheckCircleOutline color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary={question} />
                                </ListItem>
                            ))}
                        </List>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <SchoolIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText 
                                    primary="Wrap-Up Discussion"
                                    secondary="Teacher summarizes key points, emphasizing that AI is a tool to enhance, not replace, human interpretation."
                                />
                            </ListItem>
                        </List>
                    </Box>
                </Box>

                {/* Differentiation Section */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h4" color="primary" gutterBottom>
                        Differentiation
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <CheckCircleOutline color="primary" />
                            </ListItemIcon>
                            <ListItemText 
                                primary="Beginners"
                                secondary="Use guided prompts with simpler texts or focus on identifying only one theme or character motivation."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CheckCircleOutline color="primary" />
                            </ListItemIcon>
                            <ListItemText 
                                primary="Intermediates"
                                secondary="Analyze more complex passages with multiple themes and discuss both AI-generated and personal insights in depth."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CheckCircleOutline color="primary" />
                            </ListItemIcon>
                            <ListItemText 
                                primary="Advanced"
                                secondary="Critically evaluate AI’s limitations in literary analysis, discussing abstract themes, symbolism, and nuanced character motivations."
                            />
                        </ListItem>
                    </List>
                </Box>

                {/* Resources Section */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h4" color="primary" gutterBottom>
                        Resources Required
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <AssignmentIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText 
                                primary="Laptop or Tablet"
                                secondary="Each group needs access to a device for interacting with ChatGPT or another AI tool."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <AssignmentIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="Whiteboard and Markers: For brainstorming and displaying key concepts or debate points." />
                        </ListItem>
                    </List>

                    <Typography variant="h5" color="primary" sx={{ mt: 4, mb: 2 }}>
                        Printable Handouts
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <AssignmentIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="Pre-configured prompts for ChatGPT." />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <AssignmentIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="Tracking Worksheet for AI and student insights." />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <AssignmentIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="Reflection Worksheet for evaluating AI’s contributions." />
                        </ListItem>
                    </List>
                </Box>
            </Box>
        </>
    );
};

export default LessonPlanObjectivesKeyStage3;
