import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CircleIcon from '@mui/icons-material/Circle';
import PersonIcon from '@mui/icons-material/Person';
import TimelineIcon from '@mui/icons-material/Timeline';
import {
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography
} from '@mui/material';

const AiPromptsKeyStage2 = () => {
    return (
        <>
            <Box sx={{ mt: 4 }}>
                {/* Introduction */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h4" gutterBottom>
                        AI Prompts for Creative Exploration and Poetry Performance
                    </Typography>
                    <Typography variant="body1" paragraph>
                        These prompts and tools are designed to guide students in crafting and analyzing poetry, as well as performing it with enhanced expression using AI tools.
                    </Typography>
                </Box>

                {/* Poetry Creation Prompts */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h5" color="primary" gutterBottom>
                        1. Poetry Creation Prompts
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Starter Prompts:
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <AutoAwesomeIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="Write a short poem about a magical forest using vivid imagery and similes." />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <AutoAwesomeIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="Create a poem about the ocean with a mix of metaphors and alliteration." />
                        </ListItem>
                    </List>
                    <Typography variant="body1" paragraph>
                        Interactive Prompts:
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <AutoAwesomeIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="Expand this student-written line: ‘The stars twinkled like…’" />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <AutoAwesomeIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="Rewrite this line to sound more dramatic: ‘The night was quiet.’" />
                        </ListItem>
                    </List>
                </Box>

                {/* Poetry Analysis Prompts */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h5" color="primary" gutterBottom>
                        2. Poetry Analysis Prompts
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <CircleIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="Analyze this line for its use of figurative language: ‘The moon was a silver lantern lighting the way.’" />
                        </ListItem>
                    </List>
                </Box>

                {/* Using ElevenLabs for Poetry Performance */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h5" color="primary" gutterBottom>
                        Using ElevenLabs for Poetry Performance
                    </Typography>
                    <Typography variant="body1" paragraph>
                        ElevenLabs is a simple text-to-speech tool that creates natural voiceovers. Teachers can use it to bring poetry to life by exploring tone, pacing, and mood.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        <strong>How to Use ElevenLabs:</strong>
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <CircleIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="1. Set Up ElevenLabs: Visit ElevenLabs and create a free account. Open the text-to-speech tool and choose a voice." />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CircleIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="2. Input and Adjust: Paste the poem (e.g., ‘The wind howled through the night, shaking trees in its fright.’). Select a voice and adjust tone and pacing. Generate and play the audio for the class." />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CircleIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="3. Discuss and Experiment: Create multiple versions with different voices or speeds and compare." />
                        </ListItem>
                    </List>
                </Box>

                {/* Prompts for Poetry Performance */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h5" color="primary" gutterBottom>
                        Prompts for Poetry Performance
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <TimelineIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="Input: ‘The wind howled through the night, shaking trees in its fright.’ Adjustments: Deep voice for ‘howled,’ slow pacing for ‘fright.’" />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <TimelineIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="Input: ‘The stars danced like diamonds in the sky.’ Adjustments: Cheerful voice with light, quick pacing for a playful tone." />
                        </ListItem>
                    </List>
                </Box>
            </Box>
        </>
    );
};

export default AiPromptsKeyStage2;