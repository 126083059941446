import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CircleIcon from '@mui/icons-material/Circle';
import PersonIcon from '@mui/icons-material/Person';
import TimelineIcon from '@mui/icons-material/Timeline';
import {
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography
} from '@mui/material';

const AiPromptsKeyStage2 = () => {
    return (
        <>
            <Box sx={{ mt: 4 }}>
                {/* Introduction */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h4" gutterBottom>
                        AI Prompts and Activities for Story Creation
                    </Typography>
                    <Typography variant="body1" paragraph>
                        This section equips teachers with impactful AI prompts and activities to use during the module. AI acts as a co-creator, helping to extend student ideas, model storytelling techniques, and provide creative inspiration.
                    </Typography>
                </Box>

                {/* AI Prompts for Story Creation */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h5" color="primary" gutterBottom>
                        AI Prompts for Story Creation
                    </Typography>

                    <Typography variant="body1" paragraph>
                        1. Extending Stories:
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <AutoAwesomeIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText 
                                primary="Prompt: ‘Write a short continuation of a story where [main character] discovers a secret in [setting].’" 
                                secondary="Example Output: ‘Lila found a magical key in the forest. It started glowing when she held it close to the old oak tree…’" 
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <AutoAwesomeIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText 
                                primary="Classroom Use: Ask, ‘What do you think will happen next? What might the magical key unlock?’" 
                            />
                        </ListItem>
                    </List>

                    <Typography variant="body1" paragraph>
                        2. Character-Driven Stories:
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <PersonIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText 
                                primary="Prompt: ‘Create a story about a [character] who has a unique problem to solve in [setting].’" 
                                secondary="Example Output: ‘A friendly dragon who can’t breathe fire wants to light a campfire for his friends.’" 
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <PersonIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText 
                                primary="Classroom Use: Discuss, ‘What creative solutions can the dragon find? How would the story change if the dragon learned a new skill?’" 
                            />
                        </ListItem>
                    </List>

                    <Typography variant="body1" paragraph>
                        3. Alternative Endings:
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <TimelineIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText 
                                primary="Prompt: ‘Rewrite the ending of a story where the hero doesn’t win. What happens next?’" 
                                secondary="Example Output: ‘Instead of winning the race, Max trips and learns the value of helping others.’" 
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <TimelineIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText 
                                primary="Classroom Use: Ask, ‘Do you like this ending? What other endings could we imagine?’" 
                            />
                        </ListItem>
                    </List>
                </Box>

                {/* AI-Driven Classroom Activities */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h5" color="primary" gutterBottom>
                        AI-Driven Classroom Activities
                    </Typography>

                    <Typography variant="body1" paragraph>
                        Activity 1: "Choose Your Adventure"
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <CircleIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText 
                                primary="Objective: Students guide AI to create a branching story." 
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CircleIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText 
                                primary="Steps: Teacher inputs, ‘Start a story about a brave cat exploring a castle. Include two choices at the end of the first paragraph.’" 
                                secondary="Example Output: ‘The cat found two doors: one glowing with golden light, the other dark and mysterious.’" 
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CircleIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText 
                                primary="Class votes on which door to explore, and the teacher inputs the choice. Continue the process for a collaborative storytelling experience." 
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CircleIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText 
                                primary="Impact: Encourages decision-making and creativity." 
                            />
                        </ListItem>
                    </List>

                    <Typography variant="body1" paragraph>
                        Activity 2: "AI Story Improvement"
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <CircleIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText 
                                primary="Objective: Students critique and improve an AI-generated story." 
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CircleIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText 
                                primary="Steps: Teacher generates a simple story using AI." 
                                secondary="Example Prompt: ‘Write a story about a child who finds a magical pen.’" 
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CircleIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText 
                                primary="Students discuss: ‘What do we like about this story?’ ‘What’s missing or unclear?’ ‘How could we make the story more exciting?’" 
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CircleIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText 
                                primary="Teacher inputs student suggestions into AI for a revised version." 
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CircleIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText 
                                primary="Impact: Develops critical thinking and editing skills." 
                            />
                        </ListItem>
                    </List>
                </Box>
            </Box>
        </>
    );
};

export default AiPromptsKeyStage2;
