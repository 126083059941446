import React from 'react';
import {Box, Divider, Grid, List, ListItem, ListItemAvatar, Typography} from "@mui/material";
import ReactPlayer from 'react-player'
import {MainLayout} from "../../layouts/main-layout/MainLayout";

const Learning = () => {
  return (
    <MainLayout>
      <Grid container direction={'row'} sx={{padding: 2}}>
        <Grid item xs={12} sm={8} sx={{paddingRight: 1}}>
          <Box sx={{flex: 1, p: 0}}>
            <ReactPlayer
              width='100%'
              url='https://www.youtube.com/watch?v=TdcK9MYvJys'
              className='react-player'
            />
          </Box>

          <Box sx={{marginTop: 2, borderRadius: 2, backgroundColor: '#ffff', padding: 2}}>
            <Typography variant={'h5'}><strong>AI fundamentals</strong></Typography>
            <Typography variant={'body1'} sx={{marginTop: 2}}>
              Artificial Intelligence (AI) refers to the development of computer systems that can perform tasks that
              typically require human intelligence. These tasks include learning, reasoning, problem-solving,
              understanding natural language, and perception. AI systems leverage algorithms and large datasets to
              recognize patterns, make decisions, and improve performance over time. There are two main types of AI:
              narrow or weak AI, designed for specific tasks like image recognition or language translation, and general
              or strong AI, which possesses human-like cognitive abilities across various domains. Machine learning, a
              subset of AI, empowers systems to learn from data without explicit programming. AI technologies, such as
              neural networks and deep learning, emulate the human brain's structure to enhance learning and
              decision-making. While AI has made significant strides in various fields, ethical considerations,
              potential
              biases in algorithms, and the impact on employment and privacy continue to be important topics of
              discussion
              in the development and deployment of AI systems.</Typography>
          </Box>
        </Grid>

        <Grid items xs={12} sm={4} sx={{backgroundColor: '#ffff', borderRadius: 5, p: 2}}>
          <Typography variant={'h5'}><strong>Lessons</strong></Typography>
          <List>
            <ListItem>
              <Typography variant={"body1"}>1.</Typography>
              <ListItemAvatar sx={{marginLeft: 1}}>
                <img
                  src={"https://img.freepik.com/free-photo/ai-technology-microchip-background-digital-transformation-concept_53876-124669.jpg"}
                  style={{width: '40px'}}/>
              </ListItemAvatar>
              <Typography><strong>Future of work in a post AI world</strong></Typography>
            </ListItem>
            <Divider/>
            <ListItem>
              <Typography variant={"body1"}>2.</Typography>
              <ListItemAvatar sx={{marginLeft: 1}}>
                <img
                  src={"https://img.freepik.com/free-photo/ai-technology-microchip-background-digital-transformation-concept_53876-124669.jpg"}
                  style={{width: '40px'}}/>
              </ListItemAvatar>
              <Typography><strong>General Principles of AI 2</strong></Typography>
            </ListItem>
            <Divider/>
            <ListItem>
              <Typography variant={"body1"}>3.</Typography>
              <ListItemAvatar sx={{marginLeft: 1}}>
                <img
                  src={"https://img.freepik.com/free-photo/ai-technology-microchip-background-digital-transformation-concept_53876-124669.jpg"}
                  style={{width: '40px'}}/>
              </ListItemAvatar>
              <Typography><strong>AI for the modern manager</strong></Typography>
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default Learning;
