import AssignmentIcon from '@mui/icons-material/Assignment';
import BuildIcon from '@mui/icons-material/Build';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import GroupIcon from '@mui/icons-material/Group';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import PeopleIcon from '@mui/icons-material/People';
import QuizIcon from '@mui/icons-material/Quiz';
import SchoolIcon from '@mui/icons-material/School';
import TimerIcon from '@mui/icons-material/Timer';
import TargetIcon from '@mui/icons-material/TrackChanges';
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";

const LessonPlanObjectivesKeyStage2 = () => {

    return (
        <>
            <Box sx={{ mt: 4 }}>
                {/* Main Title and Session Objectives */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h3" color="primary" gutterBottom>
                        Lesson Plan & Objectives
                    </Typography>
                    
                    <Typography variant="h4" color="primary" sx={{ mt: 4, mb: 2 }}>
                        Session Objectives
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <TargetIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{ variant: 'h6' }}
                                primary="Enhance Creative Writing Skills"
                                secondary="Students will develop creative writing skills with support from AI, focusing on narrative structure, character development, and descriptive language."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <SchoolIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{ variant: 'h6' }}
                                primary="Introduce AI as a Writing Partner"
                                secondary="Students will learn how AI can assist in the brainstorming and writing process by generating story ideas and offering feedback."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <TimerIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{ variant: 'h6' }}
                                primary="Foster Metacognitive Reflection"
                                secondary="Students will reflect on their writing process and consider how AI's suggestions influenced their work."
                            />
                        </ListItem>
                    </List>
                </Box>

                {/* Learning Lab Section */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h4" color="primary" gutterBottom>
                        Part 1: Learning Lab
                    </Typography>

                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h5" color="primary" gutterBottom>
                            Icebreaker Activity (10 min)
                        </Typography>
                        <Typography variant="h6" color="primary" sx={{ mb: 2 }}>
                            Story Starters Circle
                        </Typography>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <GroupIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText 
                                    primary="Students sit in a circle, and each is given a unique 'story starter' prompt (e.g., 'Once upon a time, in a world where animals could talk…')."
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <GroupIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText 
                                    primary="Each student adds one sentence to the story, building on the previous student's sentence."
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <GroupIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText 
                                    primary="This activity sparks creativity and warms up students for writing, while showing how ideas can evolve collaboratively."
                                />
                            </ListItem>
                        </List>
                    </Box>

                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h5" color="primary" gutterBottom>
                            Introduction to AI as a Writing Tool (10 min)
                        </Typography>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <MenuBookIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText 
                                    primary="Presentation"
                                    secondary="Teacher explains how AI can assist in creative writing by offering suggestions, generating ideas, and giving feedback."
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <SchoolIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText 
                                    primary="Key Concepts"
                                    secondary="Discuss AI's capabilities and limitations in writing, emphasizing that AI is a tool to enhance human creativity rather than replace it."
                                />
                            </ListItem>
                        </List>
                    </Box>

                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h5" color="primary" gutterBottom>
                            Activity 1: Brainstorming with AI (15 min)
                        </Typography>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <AssignmentIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText 
                                    primary="Pair Activity"
                                    secondary="Divide students into pairs. Each pair uses an AI-powered tool like ChatGPT or Anthropic to brainstorm a story idea based on a chosen prompt."
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <BuildIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText 
                                    primary="AI Interaction"
                                    secondary="Students take turns asking AI for suggestions on plot points, character traits, or setting descriptions. They select ideas they like and ignore those that don't fit their vision."
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <AssignmentIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText 
                                    primary="Materials"
                                    secondary="Provide a brainstorming worksheet to help students record ideas generated by AI and their own thoughts."
                                />
                            </ListItem>
                        </List>
                    </Box>

                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h5" color="primary" gutterBottom>
                            Activity 2: Plot and Character Development (10 min)
                        </Typography>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <FormatListBulletedIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText 
                                    primary="Instructions"
                                    secondary="In pairs, students expand on their brainstormed ideas by creating an outline for their story, focusing on key plot points and character traits."
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <MenuBookIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText 
                                    primary="Mini Presentation"
                                    secondary="Teacher demonstrates a simple story outline and introduces a character-building framework (e.g., 'What does the character want? What challenges do they face?')."
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <AssignmentIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText 
                                    primary="Materials"
                                    secondary="Provide a story outline template to guide students in organizing their thoughts."
                                />
                            </ListItem>
                        </List>
                    </Box>

                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h5" color="primary" gutterBottom>
                            Comfort Break (5 min)
                        </Typography>
                    </Box>
                </Box>

                {/* Part 2: Practice Pad */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h4" color="primary" gutterBottom>
                        Part 2: Practice Pad
                    </Typography>

                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h5" color="primary" gutterBottom>
                            Writing the First Draft with AI Support (15 min)
                        </Typography>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <AssignmentIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText 
                                    primary="Individual Writing Activity"
                                    secondary="Students begin writing the first draft of their story, using AI for suggestions on descriptive language, dialogue, or story progression."
                                />
                            </ListItem>
                        </List>

                        <Typography variant="h6" color="primary" sx={{ mt: 2, mb: 1 }}>
                            Differentiation
                        </Typography>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <SchoolIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText primary="Beginners: Focus on completing a single paragraph with AI's help." />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <SchoolIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText primary="Intermediates: Write a full story beginning, focusing on character introduction and setting." />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <SchoolIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText primary="Advanced: Write a complete story outline, using AI to refine plot details and ensure coherence." />
                            </ListItem>
                        </List>
                    </Box>

                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h5" color="primary" gutterBottom>
                            Reflecting on AI's Role in Writing (10 min)
                        </Typography>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <PeopleIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText 
                                    primary="Group Discussion"
                                    secondary="Students share one way AI helped them in their story and one area they changed independently."
                                />
                            </ListItem>
                        </List>

                        <Typography variant="h6" color="primary" sx={{ mt: 2, mb: 1 }}>
                            Prompts for Discussion
                        </Typography>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <QuizIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText primary="Did AI suggest any ideas you hadn't thought of?" />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <QuizIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText primary="How did AI help improve your story?" />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <QuizIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText primary="What part of the story did you feel was truly yours, and why?" />
                            </ListItem>
                        </List>
                    </Box>

                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h5" color="primary" gutterBottom>
                            Plenary (5 min)
                        </Typography>
                        <Typography variant="h6" color="primary" sx={{ mt: 2, mb: 1 }}>
                            Mentimeter Poll
                        </Typography>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <CheckCircleIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText primary="Question 1: 'How helpful was AI in your writing today?' (Scale 1-5)" />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <CheckCircleIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText primary="Question 2: 'What's one thing you learned about using AI in storytelling?'" />
                            </ListItem>
                        </List>

                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <MenuBookIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText 
                                    primary="Wrap-Up Discussion"
                                    secondary="Teacher summarizes key insights on AI's role as a creative writing tool."
                                />
                            </ListItem>
                        </List>
                    </Box>
                </Box>

                {/* Resources Section */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h4" color="primary" gutterBottom>
                        Resources Required
                    </Typography>

                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <BuildIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText 
                                primary="Laptop or Tablet"
                                secondary="Each student or pair should have access to a device with internet for AI-based brainstorming and drafting."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <BuildIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="Whiteboard and Markers: For brainstorming and displaying story structure examples." />
                        </ListItem>
                    </List>

                    <Typography variant="h5" color="primary" sx={{ mt: 4, mb: 2 }}>
                        Handouts
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <AssignmentIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="Brainstorming Worksheet" />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <AssignmentIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="Story Outline Template" />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <AssignmentIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="Reflection Worksheet" />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <AssignmentIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="Writing with AI Guide (includes sample prompts for using AI)" />
                        </ListItem>
                    </List>
                </Box>
            </Box>
        </>
    );
};

export default LessonPlanObjectivesKeyStage2;
