import { Grid, Typography, Box } from "@mui/material";
import { ProfileImage } from "./profile-image";
import {UserDetails} from "./user-details";
import {MainLayout} from "../../layouts/main-layout/MainLayout";

export const Profile = () => {
    return (
        <MainLayout>
            <Box sx={{p: 5}}>

          <Typography variant={"h2"} fontWeight={600}>My Profile</Typography>
            <Grid container spacing={4} sx={{p:5}}>
                <Grid item xs={12} md={5} lg={4} xl={3}>
                    <ProfileImage />
                </Grid>
                <Grid item xs={12} md={7} lg={8} xl={9}>
                    <UserDetails />
                </Grid>
            </Grid>
            </Box>
        </MainLayout>
    )
}