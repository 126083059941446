import React, { useState } from "react";
import { Box, Button, InputAdornment, MenuItem, Paper, Select, Stack, TextField, Typography, IconButton } from "@mui/material";
import { IconEye, IconPlus, IconSearch, IconSortAscending, IconSortDescending } from "@tabler/icons-react";
import { grey } from "@mui/material/colors";
import moment from "moment";
import { CreateIdeaModal } from "./CreateIdeaModal";
import { useNavigate } from "react-router-dom";

export const Ideas = ({ title = "Explore Ideas", ideas = [], onClick }) => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [sortOrder, setSortOrder] = useState("date-created-asc");
  const navigate = useNavigate();

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', overflow: 'hidden', p:5 }}>
      <CreateIdeaModal
        open={open}
        handleClose={() => setOpen(false)}
      />
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        mb={3}
        maxWidth={"100%"}
      >
        <Typography variant={"h5"} fontWeight={700}>{title}</Typography>
        <Button
          variant={"contained"}
          color={"info"}
          startIcon={<IconPlus size={15} />}
          onClick={() => setOpen(true)}
          sx={{
            borderRadius: 5,
            textTransform: "none"
          }}
        >
          New Idea
        </Button>
      </Stack>
      <Stack
        direction={"row"}
        alignItems={"center"}
        gap={2}
        mb={2}
      >
        <TextField
          size={"small"}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder={"Find an idea"}
          InputProps={{
            sx: { borderRadius: 7, bgcolor: grey[100] },
            startAdornment: <InputAdornment position={"start"}>
              <IconSearch size={15} />
            </InputAdornment>
          }}
          sx={{ flexGrow: 1 }}
        />
        <Select
          variant={"standard"}
          size={"small"}
          value={sortOrder}
          onChange={(e) => setSortOrder(e.target.value)}
          sx={{ minWidth: "120px" }}
        >
          <MenuItem value={"title-asc"}>
            <IconSortAscending size={15} style={{ marginRight: 5 }} /> Title
          </MenuItem>
          <MenuItem value={"title-desc"}>
            <IconSortDescending size={15} style={{ marginRight: 5 }} /> Title
          </MenuItem>
          <MenuItem value={"date-created-asc"}>
            <IconSortAscending size={15} style={{ marginRight: 5 }} /> Date Created
          </MenuItem>
          <MenuItem value={"date-created-desc"}>
            <IconSortDescending size={15} style={{ marginRight: 5 }} /> Date Created
          </MenuItem>
        </Select>
      </Stack>
      <Box sx={{ 
        pr: 2, 
        width: "100%", 
        maxWidth: "100%",
    
      }}>
        {ideas
          .filter(idea => idea?.title?.toLowerCase().includes(search.toLowerCase()))
          .sort((a, b) => {
            switch (sortOrder) {
              case "title-asc": return a.title.localeCompare(b.title);
              case "title-desc": return b.title.localeCompare(a.title);
              case "date-created-asc": return new Date(a.createdAt) - new Date(b.createdAt);
              case "date-created-desc": return new Date(b.createdAt) - new Date(a.createdAt);
              default: return 0;
            }
          })
          .map(idea => <IdeaItem key={idea?.uid} idea={idea} onClick={() => onClick?.(idea)} />)
        }
      </Box>
    </Box>
  )
}

const IdeaItem = ({ idea, onClick }) => (
  <Box 
    onClick={onClick}
    component={Paper}
    sx={{
      p: 2,
      mb: 2,
      width: "100%",
      borderRadius: 2,
      cursor: "pointer",
      transition: "background-color 0.3s",
      boxSizing: 'border-box',
      overflow: 'hidden',
      '&:hover': {
        bgcolor: '#f0f0f0',
      }
    }}
  >
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      mb={1}
      sx={{ width: '100%' }}
    >
      <Typography 
        variant="subtitle1" 
        fontWeight={700} 
        sx={{ 
          flexGrow: 1,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          maxWidth: 'calc(60vw - 100px)', // Adjust based on the date width
        }}
      >
        {idea?.title}
      </Typography>
      <Typography 
        variant="caption" 
        color="text.secondary"
        sx={{ 
          flexShrink: 0, 
          ml: 1,
          minWidth: '85px', // Adjust based on your date format
        }}
      >
        {moment(idea?.createdAt).format("MMM DD, YYYY")}
      </Typography>
    </Stack>
    <Typography variant="body2" mb={1}>Author: {idea?.author?.displayName}</Typography>
    <Typography 
      variant="body2" 
      sx={{
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: 2,
        WebkitBoxOrient: "vertical",
        lineHeight: 1.5,
      }}
    >
      {idea?.description}
    </Typography>
    {/* <IconButton 
      sx={{
        mt: 1,
        textTransform: 'none', // Prevents automatic uppercase transformation
      }}
      size='small' 
            onClick={() => onClick}
    >
      <IconEye size={15} />
    </IconButton> */}
  </Box>
);
