import React, {useContext, useEffect, useState} from "react";
import {
  Button,
  Checkbox,
  Fade, FormControl,
  FormControlLabel,
  IconButton,
  InputLabel, MenuItem,
  Select,
  Stack,
  TextField
} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  IconTrash,
  IconX
} from "@tabler/icons-react";
import Modal from "@mui/material/Modal";
import {grey} from "@mui/material/colors";
import {toast} from "react-toastify";
import {v4 as uuidv4} from "uuid";
import {auth} from "../../config/firebase";
import {Context as AppContext} from "../../context/AppContext";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: "280px",
  width: "100wv",
  maxHeight: "80vh",
  bgcolor: 'background.paper',
  borderRadius: "0.75rem",
  border: '0px solid #000',
  boxShadow: 24,
  p: 3,
  overflowY: "auto"
};

export const CreateIdeaModal = ({open, handleClose, editIdea}) => {
  const {createOrUpdateIdea,runSimilarityCheck} = useContext(AppContext);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    if (editIdea) {
      setTitle(editIdea?.title);
      setDescription(editIdea?.description);
    }
  }, [editIdea])

  const onCreate = async () => {
    if (!title) {
      toast.error("All fields are required!");
      return;
    }
    if (!description) {
      toast.error("All fields are required!");
      return;
    }
    let newIdea = {
      uid: uuidv4(),
      author: {
        uid: auth.currentUser?.uid || null,
        displayName: auth.currentUser.displayName || auth?.currentUser?.email || "Anonymous",
        photoURL: auth?.currentUser?.photoURL || null
      },
      similarityToOthers: [],
      createdAt: new Date().getTime(),
    }
    if (editIdea) {
      newIdea = {
        ...editIdea,
        similarityToOthers: []
      }
    }
    newIdea.title = title;
    newIdea.description = description;

    try {
      await createOrUpdateIdea(newIdea);
      onClose();
      await runSimilarityCheck(newIdea?.uid);
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
  }

  const onClose = () => {
    setDescription("");
    setTitle("");
    handleClose();
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Fade in={open} timeout={500}>
        <Box>
          <Box sx={{position: "fixed", top: 10, right: 10}}>
            <IconButton
              size={"small"}
              onClick={onClose}
              color={"#FFF"}
            >
              <IconX color={grey[300]}/>
            </IconButton>
          </Box>

          <Stack maxWidth={"sm"} direction={"column"} gap={2} sx={style}>
            <Typography variant={"h5"} fontWeight={"bold"} sx={{mb: 1}}>Add new idea</Typography>
            <TextField
              size={"small"}
              label={"Title"}
              value={title}
              required
              onChange={e => setTitle(e.target.value)}
            />
            <TextField
              size={"small"}
              label={"Description"}
              value={description}
              multiline
              rows={3}
              required
              onChange={e => setDescription(e.target.value)}
            />

            <Stack direction={'row'} gap={1} useFlexGap flexWrap={"wrap"}>
              <Button
                variant={"contained"}
                color={"warning"}
                sx={{borderRadius: 5, textTransform: "capitalize"}}
                disabled={!title && !description}
                onClick={onCreate}
              >
                Save
              </Button>
              <Button
                variant={"outlined"}
                color={"warning"}
                sx={{borderRadius: 5, textTransform: "capitalize"}}
                onClick={onClose}
              >
                Cancel
              </Button>
              {/*{*/}
              {/*  entity?.id &&*/}
              {/*  <Button*/}
              {/*    variant={"outlined"}*/}
              {/*    color={"error"}*/}
              {/*    sx={{borderRadius: 5, textTransform: "capitalize", ml: "auto"}}*/}
              {/*    onClick={() => {*/}
              {/*      onRemove(entity?.id);*/}
              {/*      onClose();*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    <IconTrash size={20} />*/}
              {/*  </Button>*/}
              {/*}*/}
            </Stack>
          </Stack>
        </Box>
      </Fade>
    </Modal>
  )
}
