import { Box } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import GptModal from '../../components/GptModal';
import PageHeader from '../../components/PageHeader';
import { Context as AppContext } from '../../context/AppContext';
import { MainLayout } from '../../layouts/main-layout/MainLayout';
import AiChat from '../ai-designer/AiChat';
const ClassGptChat = () => {
    const {state: {GPTS}, updateGPT, deleteGPT} = useContext(AppContext)
    const { uid } = useParams();
    const [gpt, setGpt] = useState(null)
    const [openModal, setOpenModal] = useState(false);
    const [assistantTitle, setAssistantTitle] = useState('');
    const [assistantBehavior, setAssistantBehavior] = useState('');
    const navigate = useNavigate();
    useEffect(() => {
        const gpt = GPTS.find(gpt => gpt.uid === uid)
        setGpt(gpt)
        if (gpt) {
            setAssistantTitle(gpt.title);
            setAssistantBehavior(gpt.description);
        }
    }, [uid, GPTS])

    

    const handleSubmit = () => {
        updateGPT({
            ...gpt,
            title: assistantTitle,
            description: assistantBehavior,
        });
        setOpenModal(false);
    };

    return (
        <MainLayout>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <PageHeader 
                    title={`In-Class GPT - `}
                    rainbowText={gpt?.title}
                />
               
            </Box>
            
            <AiChat 
                initialMessages={gpt?.messages}
                showSelectors={false}
            />

            <GptModal 
                open={openModal}
                onClose={() => setOpenModal(false)}
                onSubmit={handleSubmit}
                assistantTitle={assistantTitle}
                setAssistantTitle={setAssistantTitle}
                assistantBehavior={assistantBehavior}
                setAssistantBehavior={setAssistantBehavior}
                isEditing={true}
            />
        </MainLayout>
    );
};

export default ClassGptChat; 