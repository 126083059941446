import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CircleIcon from '@mui/icons-material/Circle';
import PersonIcon from '@mui/icons-material/Person';
import TimelineIcon from '@mui/icons-material/Timeline';
import {
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography
} from '@mui/material';

const AiPromptsKeyStage2 = () => {
    return (
        <>
            <Box sx={{ mt: 4 }}>
                {/* Introduction */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h4" gutterBottom>
                        Pre-configured Prompts for Co-Creating Stories
                    </Typography>
                    <Typography variant="body1" paragraph>
                        These prompts are structured to guide students through different stages of storytelling, including brainstorming, character development, and plot progression.
                    </Typography>
                </Box>

                {/* Brainstorming Section */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h5" color="primary" gutterBottom>
                        Brainstorming
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <AutoAwesomeIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="Can you suggest three interesting story ideas about a magical forest?" />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <AutoAwesomeIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="What could be an exciting setting for a story about two friends who go on an adventure?" />
                        </ListItem>
                    </List>
                </Box>

                {/* Character Development Section */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h5" color="primary" gutterBottom>
                        Character Development
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <PersonIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="Describe a character who is brave but also has a secret fear." />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <PersonIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="Can you help me create a backstory for a character who wants to be a scientist?" />
                        </ListItem>
                    </List>
                </Box>

                {/* Plot Progression Section */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h5" color="primary" gutterBottom>
                        Plot Progression
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <TimelineIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="What could happen next if my character finds a hidden door in the forest?" />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <TimelineIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="Can you suggest a surprising twist for a story about a treasure hunt?" />
                        </ListItem>
                    </List>
                </Box>

                {/* Descriptive Language Section */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h5" color="primary" gutterBottom>
                        Descriptive Language
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <CircleIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="Help me describe a sunset on a beach in a magical world." />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CircleIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="Can you suggest words to describe a spooky old house?" />
                        </ListItem>
                    </List>
                </Box>
            </Box>
        </>
    );
};

export default AiPromptsKeyStage2; 