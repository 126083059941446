import ArticleIcon from '@mui/icons-material/Article';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import PeopleIcon from '@mui/icons-material/People';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ShareIcon from '@mui/icons-material/Share';
import {
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography
} from '@mui/material';

export default function MetacognitiveExercise() {
    return (
        <>
            <Box sx={{ mt: 4 }}>
                <Typography variant="h4" gutterBottom>
                    Metacognitive Exercise: Think-Pair-Share Activity
                </Typography>
                <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                    Duration: 10 minutes
                </Typography>

                {/* Main Activity Steps */}
                <Box sx={{ mt: 4, mb: 6 }}>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <LightbulbIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText 
                                primary="Think"
                                secondary="Students individually reflect on how AI helped shape their story."
                                primaryTypographyProps={{ variant: 'h6', color: 'primary' }}
                            />
                        </ListItem>

                        <ListItem>
                            <ListItemIcon>
                                <PeopleIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText 
                                primary="Pair"
                                secondary="They pair up to discuss specific ways AI's suggestions influenced their writing."
                                primaryTypographyProps={{ variant: 'h6', color: 'primary' }}
                            />
                        </ListItem>

                        <ListItem>
                            <ListItemIcon>
                                <ShareIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText 
                                primary="Share"
                                secondary="Students share insights with the class, discussing which AI suggestions they kept or modified and why."
                                primaryTypographyProps={{ variant: 'h6', color: 'primary' }}
                            />
                        </ListItem>
                    </List>
                </Box>

                {/* Resources Section */}
                <Box sx={{ mt: 4 }}>
                    <Typography variant="h5" gutterBottom>
                        Resources
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <ArticleIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText 
                                primary="Reflection Worksheet"
                                secondary="Prompts students to document how AI influenced their story and what they learned from the process."
                                primaryTypographyProps={{ fontWeight: 'medium' }}
                            />
                        </ListItem>

                        <ListItem>
                            <ListItemIcon>
                                <QuestionAnswerIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText 
                                primary="Guided Discussion Prompts"
                                secondary="To help students explore how AI contributed to their creativity."
                                primaryTypographyProps={{ fontWeight: 'medium' }}
                            />
                        </ListItem>
                    </List>
                </Box>
            </Box>
        </>
    );
};