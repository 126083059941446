import React from "react";
import {Avatar, Button, Stack, Toolbar, Typography} from "@mui/material";
import {Link} from "react-router-dom";

export const MiniHeader = () => {
    return (
        <Toolbar
            sx={{
                position: "fixed",
                bgcolor: "transparent"
            }}
        >
            <Stack direction={"row"} sx={{width: "100%"}} gap={1} justifyContent={"space-between"} alignItems={"center"}>
                <Stack direction={"row"} gap={1} alignItems={"center"}>
                    <Button
                        component={Link}
                        to={"/"}
                        variant={"text"}
                        color={"secondary"}
                    >
                        <Avatar
                            src={"/aiden.png"}
                        />
                        <Typography sx={{ml: 1, color: '#ffffff'}} fontSize={18} fontWeight={"bold"}>Aiden</Typography>
                    </Button>
                </Stack>
            </Stack>
        </Toolbar>
    )
}
