import {getDownloadURL, ref, uploadBytes, deleteObject} from "firebase/storage";
import {storage} from "../config/firebase";
import oracleLogo from '../assets/oracle-logo.png';

export const uploadFile =  async (file, name) => {
  const fileFolderRef = ref(storage, `user-profiles/${name}`)
  const imgRef = await uploadBytes(fileFolderRef, file);
  return await getDownloadURL(imgRef.ref);
}

export const removeFile = async (fileUrl) => {
  const fileRef = ref(storage, fileUrl);
  await deleteObject(fileRef);
}


export function truncateWords(text, wordLimit) {
  const words = text?.split(' ');
  const truncated = words?.slice(0, wordLimit)?.join(' ');
  return words?.length > wordLimit ? `${truncated}...` : truncated;
}

export function getImageUrl(company) {
  switch (company.toLowerCase()) {
      case 'oracle' :
          return oracleLogo
      default:
          throw new Error('Company not found')
  }
}

export const capitalizeFirstWord = (str) => {
  if (!str) return '';
  const firstWord = str.split(' ')[0];
  return firstWord.charAt(0).toUpperCase() + firstWord.slice(1).toLowerCase();
};