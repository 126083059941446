import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CircleIcon from '@mui/icons-material/Circle';
import PersonIcon from '@mui/icons-material/Person';
import TimelineIcon from '@mui/icons-material/Timeline';
import {
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography
} from '@mui/material';

const AiPromptsKeyStage3 = () => {

    return (
        <>
            <Box sx={{ mt: 4 }}>
                {/* Introduction */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h4" gutterBottom>
                        Pre-configured Prompts for Literary Analysis
                    </Typography>
                    <Typography variant="body1" paragraph>
                        These prompts are designed to guide students through different aspects of literary analysis, including theme exploration, character study, and critical reading skills.
                    </Typography>
                </Box>

                {/* Theme Analysis Section */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h5" color="primary" gutterBottom>
                        Theme Analysis
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <AutoAwesomeIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="What themes are present in this excerpt? Provide examples." />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <AutoAwesomeIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="How does the theme of power affect the characters in this text?" />
                        </ListItem>
                    </List>
                </Box>

                {/* Character Motivations Section */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h5" color="primary" gutterBottom>
                        Character Motivations
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <PersonIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="What motivates [Character Name] in this passage?" />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <PersonIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="How do [Character Name]'s actions reflect their internal conflict?" />
                        </ListItem>
                    </List>
                </Box>

                {/* Symbolism Section */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h5" color="primary" gutterBottom>
                        Symbolism and Figurative Language
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <TimelineIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="What symbols appear in this passage? What do they represent?" />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <TimelineIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="Can you suggest a symbol that represents the theme of love in this text?" />
                        </ListItem>
                    </List>
                </Box>

                {/* Descriptive Language Section */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h5" color="primary" gutterBottom>
                        Descriptive Language
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <CircleIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="Help me describe a sunset on a beach in a magical world." />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CircleIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="Can you suggest words to describe a spooky old house?" />
                        </ListItem>
                    </List>
                </Box>
            </Box>
        </>
    );
};

export default AiPromptsKeyStage3; 