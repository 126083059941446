import { useParams, useNavigate } from 'react-router-dom';
import PageWrapper from '../../components/PageWrapper';
import { useContext, useEffect, useState } from 'react';
import { Context as AppContext } from '../../context/AppContext';
import {
    IconFolder,
    IconBookmarks
} from '@tabler/icons-react';
import { Box, Stack, Typography, Avatar, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { componentMapping } from '../../utils/enums';

const ModuleItems = () => {
    const { className, moduleName } = useParams();
    const {state:{modules}} = useContext(AppContext);
    const [module, setModule] = useState([]);
    const [selectedComponent, setSelectedComponent] = useState(null);

    useEffect(() => {
        const module = modules.find(m => m.module === moduleName);
        setModule(module);
        // Set the first component as default when module changes
        if (module?.components?.length > 0) {
            setSelectedComponent(module.components[0]);
        }
    }, [moduleName, modules]);

    const renderComponent = () => {
        if (!selectedComponent) return null;
        
        const Component = componentMapping[selectedComponent.componentName];
        return Component ? <Component /> : null;
    };

    return (
        <PageWrapper title={`${module?.title}`}>
            <Stack spacing={3} sx={{marginTop: 2}}> 
                <FormControl fullWidth>
                    <InputLabel>Select Module Resource</InputLabel>
                    <Select
                        value={selectedComponent?.componentName || ''}
                        label="Select Module Resource"
                        onChange={(e) => {
                            const component = module.components.find(
                                c => c.componentName === e.target.value
                            );
                            setSelectedComponent(component);
                        }}
                    >
                        {module?.components?.map((item) => (
                            <MenuItem key={item.componentName} value={item.componentName}>
                                {item.title}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {/* Updated render area for the selected component */}
                <Box>
                    {renderComponent()}
                </Box>
            </Stack>
        </PageWrapper>
    );
};

export default ModuleItems; 