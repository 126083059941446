import ArticleIcon from '@mui/icons-material/Article';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import PeopleIcon from '@mui/icons-material/People';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ShareIcon from '@mui/icons-material/Share';
import SearchIcon from '@mui/icons-material/Search';
import PersonIcon from '@mui/icons-material/Person';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import {
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography
} from '@mui/material';

const MetacognitiveExerciseKeyStage3 = () => {
    return (
        <>
            <Box sx={{ mt: 4 }}>
                {/* Header Section */}
                <Typography variant="h4" gutterBottom>
                    Metacognitive Exercise: Compare and Reflect
                </Typography>
                <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                    Purpose: Encourage students to reflect on the insights provided by AI and their own interpretations, promoting critical thinking and deeper understanding of how analysis is formed.
                </Typography>

                {/* What Did You Learn Section */}
                <Box sx={{ mt: 4, mb: 6 }}>
                    <Typography variant="h5" color="primary" gutterBottom>
                        What Did You Learn?
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <LightbulbIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText 
                                primary="AI Insights"
                                secondary="Write down two things AI pointed out that you found surprising or insightful."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <QuestionAnswerIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText 
                                primary="Points of Disagreement"
                                secondary="Write down one point where you disagreed with AI and explain why."
                            />
                        </ListItem>
                    </List>
                </Box>

                {/* Evaluate the Process Section */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h5" color="primary" gutterBottom>
                        Evaluate the Process
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <SearchIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText 
                                primary="Missing Elements"
                                secondary="Did AI miss any important themes or details? What were they?"
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <PersonIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText 
                                primary="Personal Interpretation"
                                secondary="Was there a moment where you relied more on your own interpretation than AI's? Why?"
                            />
                        </ListItem>
                    </List>
                </Box>

                {/* Think-Pair-Share Activity */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h5" color="primary" gutterBottom>
                        Think-Pair-Share Instructions
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <LightbulbIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText 
                                primary="Think"
                                secondary="Complete the reflection questions individually."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <PeopleIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText 
                                primary="Pair"
                                secondary="Discuss your answers with a partner, focusing on how your interpretations differed from AI's and why."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <ShareIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText 
                                primary="Share"
                                secondary="Each pair shares their findings with the class. Discuss common patterns, such as AI missing subtle themes or students identifying overlooked details."
                            />
                        </ListItem>
                    </List>
                </Box>

                {/* Worksheet Layout */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h5" color="primary" gutterBottom>
                        Worksheet Sections
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <ArticleIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText 
                                primary="Section 1: AI Insights"
                                secondary="List two points AI identified and your thoughts about them."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CompareArrowsIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText 
                                primary="Section 2: Compare & Contrast"
                                secondary="Where did your analysis align with AI's? Where did it differ?"
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <AutoAwesomeIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText 
                                primary="Section 3: Reflection"
                                secondary="What would you do differently next time when using AI?"
                            />
                        </ListItem>
                    </List>
                </Box>
            </Box>
        </>
    );
};

export default MetacognitiveExerciseKeyStage3; 