import { Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import React from 'react';
import { MainLayout } from "../../layouts/main-layout/MainLayout";
import MasterPromptsContent from './MasterPrompts/MasterPromptsContent';

const MasterPrompts = () => {



    return (
        <MainLayout>
            <Box sx={{
                height: '80vh',
                p: 3,
                position: 'relative',
                display: 'flex',
                flexDirection: "column",
                overflow: 'auto',
                '&::-webkit-scrollbar': {
                    width: '8px',
                    background: 'transparent'
                },
                '&::-webkit-scrollbar-thumb': {
                    background: '#bfbfbf',
                    borderRadius: '4px',
                    '&:hover': {
                        background: '#a6a6a6'
                    }
                },
                '&::-webkit-scrollbar-track': {
                    background: 'transparent'
                }
            }}>
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    alignItems={{ xs: 'flex-start', sm: 'center' }}
                    spacing={2}
                >
                    <Typography variant={'h3'}>
                        {'Resource Hub'}
                    </Typography>
                </Stack>

               <MasterPromptsContent/>
            </Box>
        </MainLayout>
    );
};

export default MasterPrompts;
