import { Box, Stack, Typography } from "@mui/material";
import moment from "moment/moment";
import React, { useContext, useEffect, useState } from "react";
import { Context as AppContext } from "../../context/AppContext";
import { MainLayout } from "../../layouts/main-layout/MainLayout";
import { useParams } from "react-router-dom";
export const Idea = ({idea}) => {
  const {state: {ideas}} = useContext(AppContext);
  const [similarIdeas, setSimilarIdeas] = useState([]);
  const { id } = useParams(); // This extracts the UID from the URL

  useEffect(() => {
    if (ideas?.length) {
      const data = [...ideas];
      console.log("id", id);
      const idea = data.find(i => i.uid === id);
      if (idea) {
        let similarIdeasItems = [];
        if (idea?.similarityToOthers?.length > 0) {
          for (const item of data) {
            const similar = idea?.similarityToOthers?.find(sim => sim.uid === item.uid);
            if (similar) {
              item.similarity = similar?.similarity;
              similarIdeasItems.push(item);
            }
          }
        }
        similarIdeasItems = similarIdeasItems.sort((a, b) => b.similarity - a.similarity);
        console.log("similarIdeasItems", similarIdeasItems);
        setSimilarIdeas(similarIdeasItems);
      }
    }
  }, [id, ideas])

  return (
    <MainLayout>
    <Stack direction={"column"} alignItems={"center"} gap={2} sx={{ p: 5}}>
      <Box maxWidth={"md"} sx={{width: "100%"}}>
        <Typography
          fontSize={20}
          fontWeight={700}
          align={"left"}
          >
          Similar ideas:
        </Typography>
      </Box>
      {
        similarIdeas?.map(i => <IdeaItem key={i.uid} idea={i}/>)
      }
      {similarIdeas?.length === 0 &&
        <Typography fontSize={16}>There are no similar ideas found, please check back when more ideas have been added to
          the portal</Typography>}
    </Stack>
</MainLayout>
  )
}


const IdeaItem = ({idea}) => {
  let color = "#000";
  if (idea?.similarity > 75) {
    color = "#00DD22"
  } else if (idea?.similarity > 50) {
    color = "#c0b70a"
  }
  return (
    <Box
      sx={{
        borderColor: "divider",
        width: "100%",
        bgcolor: "#FFF",
        borderRadius: 5,
      }}
    >
      <Box
        sx={{p: 2}}
      >
        <Stack direction={"row"} gap={2} justifyContent={"space-between"} alignItems={"center"} sx={{width: "100%"}}>
          <Typography
            fontWeight={700}
            fontSize={20}
            noWrap
          >
            {idea?.title}
          </Typography>
          <Typography
            fontWeight={700}
            fontSize={13}
            color={"#b9b9b9"}
          >
            {moment(idea?.createdAt).format("MMM, DD yyyy HH:mm")}
          </Typography>
        </Stack>
        <Stack direction={"row"}
               gap={2}
               useFlexGap
               flexWrap={"wrap"}
               justifyContent={"space-between"}
               alignItems={"center"}
               sx={{width: "100%"}}>
          <Typography
            fontWeight={700}
            fontSize={13}
          >
            Author: {idea?.author?.displayName}
          </Typography>

          {
            idea?.similarity &&
            <Typography
              fontWeight={700}
              fontSize={13}
              color={color}
              align={"right"}
            >
              <span style={{color: "#4d4d4d"}}>Similarity:</span> {idea?.similarity.toFixed(0)}%
            </Typography>
          }
        </Stack>
        <Box
          sx={{maxWidth: "inherit", textOverflow: "ellipsis"}}
        >
          <Typography
            className={"idea-description"}
          >
            {idea?.description}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}