import { Box, Button, TextField, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
const GptModal = ({ 
    open, 
    onClose, 
    onSubmit, 
    assistantTitle, 
    setAssistantTitle, 
    assistantBehavior, 
    setAssistantBehavior,
    isEditing = false 
}) => {
    
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-title"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                maxWidth: '700px',
                width: '90%',
                height: '100%',
                maxHeight: '600px',
                display: 'flex',
                flexDirection: 'column',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: 2,
            }}>
                <Typography id="modal-title" variant="h6" component="h2" mb={2}>
                    {isEditing ? 'Edit Assistant' : 'Create New Assistant'}
                </Typography>
                
                <TextField
                    fullWidth
                    value={assistantTitle}
                    focused
                    variant="standard"
                    onChange={(e) => setAssistantTitle(e.target.value)}
                    sx={{ mb: 2, mt: 2 }}
                    placeholder="Student's friendly instructional assistant"
                />
                
                <TextField
                    fullWidth
                    multiline
                    label="Assistant Behavior Description"
                    value={assistantBehavior}
                    onChange={(e) => setAssistantBehavior(e.target.value)}
                    sx={{ 
                        mb: 2,
                        mt: 2,
                        flex: 1,
                        '& .MuiInputBase-root': {
                            height: '100%'
                        },
                        '& .MuiInputBase-input': {
                            height: '100% !important'
                        }
                    }}
                    placeholder="This GPT is designed to act as a supportive study coach..."
                />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button variant="contained" onClick={onSubmit}>
                        {isEditing ? 'Save Changes' : 'Create'}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default GptModal; 